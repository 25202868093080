import { Skeleton, Space } from "antd";
import Meta from "antd/lib/card/Meta";
import { GRAPHIC_TYPES } from "../../../../../sdk/@types/graphic.model";
import useFilters from "../../../../../core/hooks/useFilters";
import { Bar } from "@ant-design/plots";
import { useCallback, useEffect, useState } from "react";
import * as CF from "../../CardGrapich.styles";
import _ from 'lodash';
import useGraphicAll from "../../../../../core/hooks/useGraphicAll";


export default function CardGraphicGenre() {
  
  const { stateFilters } = useFilters();
  const [config, setConfig ] = useState<any>();
  const { stateGraphicAll } = useGraphicAll();

   const getItems = useCallback(async (items: any) => {
    const filters: any = stateFilters.items.find(i => i.type === GRAPHIC_TYPES.GENERO);
    const valueStrings: any[] = filters ? filters.valueStrings : ['F', 'M'];
    
    /**** MAP */
    const data = (valueStrings.length >= 1) ?
      items.filter((i: any) => valueStrings.includes(i.genero)) :
      items;
  
    const result = _(data)
      .groupBy('genero')
      .map(function(items, genero) { 
        return { genero, frequencia: items.length };
      }).value();

    const total = result.reduce((sum, current) => sum + current.frequencia, 0);
     
    const config = {
        data: result,
        xField: 'frequencia',
        yField: 'genero',
        seriesField: 'genero',
        xAxis: false,
        legend: {
          position: 'top-left',
        },
        color: (item: any) => {
          return item.genero === 'F' ? '#f04864' : '#1890ff'; 
        },
        label: {
          position: 'middle',
          content: (item: any) => {
            return (( item.frequencia / total) * 100).toFixed(2) + '%' ;
          },
        },
        tooltip: {
          formatter: (datum: any) => {
            return {
              title: null,
              name: 'Gênero',
              value: `${datum.genero}`,
            };
          },
        },
      };
      setConfig(config)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ATUALIZA O COMPONENTE QUANDO ELE É INICIADO E QUANDO A LISTA DE FILTROS É ALTERADA
  useEffect(() => {
    if (stateGraphicAll.items && stateGraphicAll.items.length > 0) {
      getItems(stateGraphicAll.items);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateGraphicAll.items]);

  return (
    <>
      <CF.Wrapper>
        <Skeleton loading={!config} avatar active>
          <Space direction="vertical" size="large" style={{ display: "flex" }}>
            <Meta title="Gráfico de Gênero" />
              <Bar {...config} />
          </Space>
        </Skeleton>
      </CF.Wrapper>
    </>
  );
}
