import { Col, Divider, Row, Space, Typography } from "antd";
import usePageTitle from "../../core/hooks/usePageTitle";
import useUsers from "../../core/hooks/useUsers";

const { Title, Paragraph } = Typography;

function HomeView() {
  usePageTitle("Home");
  
  const { stateUser } = useUsers();

  return (
    <Space direction="vertical" size={"small"} style={{ maxWidth: "100%" }}>
      <Row>
        <Col span={24}>
          <Title level={2}>{stateUser.userAuthenticated?.name}</Title>
          <Paragraph>
            Este é o resumo da empresa nos últimos doze meses
          </Paragraph>
        </Col>
        <Col span={24}>dsadfasd</Col>
      </Row>
      <Divider />
      <Row>
        <Col span={24}>
          <Title level={3}>Últimos posts</Title>
        </Col>
        <Col span={24}>sadfasdf</Col>
      </Row>
      <Row>
        <Col>
         {/* <Button
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              // const guest = createGuest('http://localhost:3000');
              // guest.get('TOKEN', function (error: any, value: any) {
              //   if (value) {
              //     console.log('**** TOKEN VALUE', value);
              //     setTokenCross(value);
              //   }
              // });
            }}
          >
            Access from Cross Domain Storage
          </Button> */}
          <div>
            <p>Value stored in cross Domain Storage</p>
          </div>
        </Col>
      </Row>
    </Space>
  );
}

export default HomeView;
