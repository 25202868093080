import { Skeleton, Space, Select, Button } from "antd";
import { useEffect, useState } from "react";
import { Filter } from "../../../../sdk/@types/filter.model";
import * as CF from "../CardFilter.styles";
import { CloseCircleTwoTone } from "@ant-design/icons";
import useFilters from "../../../../core/hooks/useFilters";
import DebounceSelect from "../DebounceSearch/DebounceSearch";
import TablesService from "../../../../sdk/services/tables.service";
import withBoundary from "../../../../core/hoc/withBoundary";
const { Option } = Select;

type CardFilterGenreProps = {
  isShow: boolean;
  filter: Filter;
  type: string;
  configType: any;
};

function CardFilterMultiple(props: CardFilterGenreProps) {
  const [loading, setLoading] = useState(true);
  const { updateFilter, deleteFilter } = useFilters();

  useEffect(() => {
    setLoading(false);
  }, [props]);

  const mapItems = (body: any[]) => {
    console.log('BODY', body)
    return  body.map((item: any) => ({
            label: item.id,
            value: item.name,
          }),
    );
  }

  const onChange = (item: any) => {
    console.log('*** ONCHANGE', item);
    onSave( item.map((items: any) => items.value));
  }


  const onChangeSelect = (value: any) => {
    onSave(value);
  }


  async function fetchList(text: string): Promise<any[]> {

    let promise: any;

    console.log('SEARCH TEXT', text.toUpperCase())
    

    if (props.type === 'RENDA') {
      promise = TablesService.getSalary().then(items => mapItems(items));
    }

    if (props.type === 'ESCOLARIDADE') {
      promise = TablesService.getEducation().then(items => mapItems(items));
    }

    if (props.type === 'PROFISSAO') {
      promise = TablesService.getOffices(text.toUpperCase()).then(items => mapItems(items));
    }

    if (props.type === 'MARCACARRO') {
      promise = TablesService.getBrandCar(text.toUpperCase()).then(items => mapItems(items));
    }

    if (props.type === 'CARGO') {
      promise = TablesService.getCargo(text.toUpperCase()).then(items => mapItems(items));
    }
    
    return promise;
  }

  const onCancel = async () => {
    await deleteFilter(props.filter.id);
  };

  const onSave = async (value: string[]) => {
    let payload: Filter = {
      ...props.filter,
      lat: 0,
      lng: 0,
      radius: 0,
      valueNumbers: props.filter.valueNumbers ?? [0, 18],
      valueStrings: value,
    };
    console.log("*** SAVE", payload);
    await updateFilter(payload);
  };

  if (!props.isShow) {
    return <div></div>;
  }

  return (
    <>
      <CF.Wrapper>
        <Skeleton loading={loading} avatar active>
          <Space
            direction="vertical"
            size="large"
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h4>{props.configType?.label}</h4>
              <div>
                <Button
                  icon={
                    <CloseCircleTwoTone
                      style={{ fontSize: "24px" }}
                      twoToneColor="#ef3535"
                    />
                  }
                  type="link"
                  size={"large"}
                  onClick={onCancel}
                />
              </div>
            </div>
            { props.type === "ESCOLARIDADE" || props.type === "PROFISSAO" ||
              props.type === "MARCACARRO" || props.type === "CARGO" ||
              props.type === "RENDA" ?
              (
              <DebounceSelect
                mode="multiple"
                defaultValue={props.filter.valueStrings || []}
                placeholder="Select users"
                fetchOptions={fetchList}
                onChange={onChange}
                style={{ width: '100%' }}
              />  
            ): (
              <Select
                mode="multiple"
                allowClear
                defaultValue={props.filter.valueStrings || []}
                style={{
                  width: "100%",
                }}
                onChange={onChangeSelect}
              >
                {props.configType &&
                  props.configType.items.map((item: any) => {
                    return (
                      <Option value={item.id} key={item.name}>
                        {item.name}
                      </Option>
                    );
                  })}
              </Select>
            )}
            
          </Space>
        </Skeleton>
      </CF.Wrapper>
    </>
  );
}

export default withBoundary(CardFilterMultiple, 'CardFilterMultiple');