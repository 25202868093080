import { BEHAVIOR_COLOR_MOCK } from "../../assets/data/progress-colors";

export default function formatCNPJ(value: number | undefined): string {
    return value === 0 ? 'não possui empresa.' : `tem ${value} empresa(s)`;
}

export function formatPartner(value: boolean | undefined): string {
    return value ? 'Possui sócios.' : 'Não possui sócios';
}

export function formatImmobileSelf(value: number | undefined): string {
    let texto: string = value && value === 1 ? `Possui ${value} imóvel` : `Possui ${value} imóvéis`;
    return value && value > 0 ? texto : 'Não possui imóvel próprio';
    
}

export function formatImmobileFamily(value: any | undefined): string {        
    const sum = value.qtdMother + value.qtdFather;
    return sum && sum > 0 ? `possui ${sum} imóveis`  : 'não possui imóvéis';
}

export function formatCar(car: any | undefined): string {
    console.log('***** PERSONA CAR', car)
    if (!car.qtd && car.qtd <= 0) { 
        return 'Não possui carro próprio';
    }
    return `Tem ${car.qtd} carro(s) próprio(s)`;
    // return car.recentChange ? `Tem ${car.qtd} carro(s) próprio(s) e realizou trocas recentes` : `Não possui carro próprio e não realizou trocas recentes`;
}

function randle(min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

export function getColor(index: number): any {
    let ale = randle(0, BEHAVIOR_COLOR_MOCK.length - 1)
    return BEHAVIOR_COLOR_MOCK[ale];
}
