import { Select } from "antd";
import { useEffect } from "react";
import useFilterTags from "../../../core/hooks/useFilterTags";
import useUsers from "../../../core/hooks/useUsers";
import { FilterTag } from "../../../sdk/@types/filter-tags.model";
import useFilters from "../../../core/hooks/useFilters";

const { Option } = Select;

export default function SelectFilter() {
  const { state, fetchFilterTags, activeFilterTags } = useFilterTags();
  const { fetchFilters } = useFilters();
  const { stateUser } = useUsers();

  useEffect(() => {
    fetchFilterTags(stateUser.userAuthenticated.teamId);
    
    if (stateUser.userAuthenticated.teamId && state.active) {
      fetchFilters({ teamId: stateUser.userAuthenticated.teamId, filterGroups: state.active });  
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

 
  const onSelect = async (e: any) => {
    await activeFilterTags(e);
    await fetchFilters({
      teamId: stateUser.userAuthenticated.teamId,
      filterGroups: e && e !== "" ? e : null,
    });
  };

  const options = state.items
    .map((d: FilterTag) => (
      <Option value={d.tagValue} key={d.tagValue}>
        {d.tagName}
      </Option>
  ));

  return (
    <>
      <Select
        onSelect={onSelect}
        filterOption={false}
        notFoundContent={null}
        showSearch
        style={{ width: 400, margin: 5, marginTop: 10 }}
        value={ state.active && state.active.tagName ? state.active.tagName : ''}
      >
        {options}
      </Select>
    </>
    
  );
}
