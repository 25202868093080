import { Skeleton, Space } from "antd";
import Meta from "antd/lib/card/Meta";
import { GRAPHIC_TYPES } from "../../../../../sdk/@types/graphic.model";
import useFilters from "../../../../../core/hooks/useFilters";
import { useCallback, useEffect, useState } from "react";
import * as CF from "../../CardGrapich.styles";
import { Pie } from "@ant-design/plots";
import useGraphicAll from "../../../../../core/hooks/useGraphicAll";
import _ from 'lodash';

export default function CardGraphicMaritalStatus() {
  
  const { stateFilters } = useFilters();
  const [config, setConfig ] = useState<any>();
  const { stateGraphicAll } = useGraphicAll();

  const getItems = useCallback(async (items: any) => {
    const filters: any = stateFilters.items.find(i => i.type === GRAPHIC_TYPES.ESTADOCIVIL);
    console.log('*** FILTERS', filters);
    const valueStrings: any[] = filters ? filters.valueStrings : [];
    
    /**** MAP */
    const data = (valueStrings.length >= 1) ?
      items.filter((i: any) => valueStrings.includes(i.status_civil)) :
      items.filter((i: any) => i.status_civil);
  
    console.log('*** ESTADO CIVIL', data);
    const result = _(data)
      .groupBy('status_civil')
      .map(function (items, status_civil) { 
        return { status_civil, frequencia: items.length };
      }).value();

    console.log('*** RESULT', result);
    // const total = result.reduce((sum, current) => sum + current.frequencia, 0);
     
    const config = {
        appendPadding: 10,
        data: result,
        angleField: "frequencia",
        colorField: "status_civil",
        radius: 1,
        innerRadius: 0.64,
        label: {
          type: "inner",
          offset: "-50%",
          style: {
            textAlign: "center",
          },
          autoRotate: false,
          // content: "{value}",
          content: (item: any) => {
            return (( item.frequencia / 500) * 100).toFixed(2) + '%' ;
          },
      },
        interactions: [
          {
            type: "element-selected",
          },
          {
            type: "element-active",
          },
          {
            type: "pie-statistic-active",
          },
        ],
      };
      setConfig(config)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ATUALIZA O COMPONENTE QUANDO ELE É INICIADO E QUANDO A LISTA DE FILTROS É ALTERADA
  useEffect(() => {
    if (stateGraphicAll.items && stateGraphicAll.items.length > 0) {
      getItems(stateGraphicAll.items);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateGraphicAll.items]);

  return (
    <>
      <CF.Wrapper>
        <Skeleton loading={!config} avatar active>
          <Space direction="vertical" size="large" style={{ display: "flex" }}>
            <Meta title="Gráfico de Estado Civil" />
            <Pie {...config} />
          </Space>
        </Skeleton>
      </CF.Wrapper>
    </>
  );
}
