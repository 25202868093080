import { Button, Col, Popconfirm, Row, Tooltip } from "antd";
import { ReactNode, useState } from "react";
import SelectFilter from "../../components/SelectFilter/SelectFilter";
import {
  FileAddTwoTone,
  DeleteTwoTone,
  DeleteOutlined,
} from "@ant-design/icons";
import FilterTagSave from "../Filters/FilterTagSave/FilterTagSave";
import useFilterTags from "../../../core/hooks/useFilterTags";
import FilterTagsService from "../../../sdk/services/filter-tags.service";
import useUsers from "../../../core/hooks/useUsers";
import useFilters from "../../../core/hooks/useFilters";
import withBoundary from "../../../core/hoc/withBoundary";

type NavBarMainProps = {
  children?: ReactNode;
  title?: string;
};

function NavBarFilter(props: NavBarMainProps) {
  const { state, setSave, activeFilterTags, fetchFilterTags } = useFilterTags();
  const { fetchFilters } = useFilters();
  const { stateUser } = useUsers();

  const [loading, setLoading] = useState(false);

  const onSave = async (value: boolean) => {
    await setSave(value);
  };

  const onDelete = async () => {
    try {
      setLoading(true);
      await FilterTagsService.delete(state.active.id);
      await fetchFilters({
        teamId: stateUser.userAuthenticated?.teamId,
        filterGroups: null,
      });
      await fetchFilterTags(stateUser.userAuthenticated?.teamId);
      const lastTag = (state.items && state.items.length > 0) ? state.items[0].tagValue : '';
      if (lastTag && lastTag !== "") { await fetchFilters({ teamId: stateUser.userAuthenticated?.teamId, filterGroups: lastTag }); }
      await activeFilterTags(lastTag);
    } catch (error: any) {
      console.log("**** ERROR", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Row
      gutter={[8, 8]}
      justify="end"
      style={{
        marginBottom: 10,
        backgroundColor: "#f4f4f4",
        borderRadius: "10",
        padding: "10px",
      }}
    >
      
      <Col
        xs={{ span: 24 }}
        sm={{ span: 12 }}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        <SelectFilter />
      </Col>
      <Col
        xs={{ span: 24, order: 2 }}
        sm={{ span: 12, order: 1 }}
        style={{
          textAlign: "right",
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          alignItems: "center",
        }}
      >
        <FilterTagSave isSave={state.isSave} />
            
        {state.isSave ? (
          <Button
            style={{ margin: 5, marginLeft: 10 }}
            type="ghost"
            danger
            icon={
              <DeleteTwoTone
                twoToneColor="#e30606"
                type="primary"
                style={{ fontSize: "18px" }}
              />
            }
            size={"large"}
            onClick={() => onSave(false)}
          >
            Cancelar
          </Button>
        ) : (
          <>
              <Col>
                <Tooltip 
                  title={stateUser.userSubscription?.isAuthorization ? 'Adicione um novo público alvo' : 'Você atingiu a quota de criação de personas'}
                  color='gold'
                  key='gold'
                >
                  <Button
                    style={{ margin: 5, marginLeft: 10 }}
                    type="ghost"
                    disabled={!stateUser.userSubscription?.isAuthorization}
                    icon={
                      <FileAddTwoTone
                        type="primary"
                        style={{ fontSize: "18px" }}
                      />
                    }
                    size={"large"}
                    onClick={() => onSave(true)}
                  >
                    Adicionar
                  </Button>
                </Tooltip>
                
            </Col>
            <Col>
              <Popconfirm
                title="Tem certeza que deseja excluir este item?"
                placement="bottomRight"
                okText="Sim"
                icon={<DeleteOutlined style={{ color: "red" }} />}
                cancelText="Não"
                onConfirm={onDelete}
              >
                <Button
                  loading={loading}
                  style={{ margin: 5, marginLeft: 10 }}
                  type="ghost"
                  danger
                  icon={
                    <FileAddTwoTone
                      type="primary"
                      twoToneColor="#e30606"
                      style={{ fontSize: "18px" }}
                    />
                  }
                  size={"large"}
                >
                  Excluir
                </Button>
              </Popconfirm>
            </Col>
          </>
        )}

        {props.children}
      </Col>
    </Row>
  );
}

export default withBoundary(NavBarFilter, 'NavBarFilter');