
// DEMOGRAFICOS

export const GENRES = [
    { id: 'M', name: 'Masculino' },
    { id: 'F', name: 'Feminino' }
];

export const NATURALIDE = [
    { id: 'BRASILEIRA', name: 'Brasileira' },
    { id: 'OUTROS', name: 'Outros' }
];

export const ESTADO_CIVIL = [
    { id: 'SOLTEIRO', name: 'Solteiro' },
    { id: 'CASADO', name: 'Casado' },
    { id: 'VIUVO', name: 'Viúvo' },
    { id: 'DIVORCIADO', name: 'Divorciado' }
];

export const ESCOLARIDADE = [
    { id: '2o_GRAU_COMPLETO_(ENSINO_MEDIO)', name: '2o_GRAU_COMPLETO_(ENSINO_MEDIO)'},
    { id: 'SUPERIOR_INCOMPLETO', name: 'SUPERIOR_INCOMPLETO'},
    { id: 'SUPERIOR_COMPLETO', name: 'SUPERIOR_COMPLETO'},
    { id: 'NAO_INFORMADO', name: 'NAO_INFORMADO'},
    { id: '1o_GRAU_COMPLETO_(ENSINO_FUNDAMENTAL)', name: '1o_GRAU_COMPLETO_(ENSINO_FUNDAMENTAL)'},
    { id: 'ATE_4a_SERIE_INCOMPLETA_DO_1o_GRAU_(ENSINO_FUNDAMENTAL)', name: 'ATE_4a_SERIE_INCOMPLETA_DO_1o_GRAU_(ENSINO_FUNDAMENTAL)'},
    { id: '2o_GRAU_INCOMPLETO_(ENSINO_MEDIO)', name: '2o_GRAU_INCOMPLETO_(ENSINO_MEDIO)'},
    { id: '5a_A_8a_SERIE_INCOMPLETA_DO_1o_GRAU_(ENSINO_FUNDAMENTAL)', name: '5a_A_8a_SERIE_INCOMPLETA_DO_1o_GRAU_(ENSINO_FUNDAMENTAL)'},
    { id: '4a_SERIE_COMPLETA_DO_1o_GRAU_(ENSINO_FUNDAMENTAL)', name: '4a_SERIE_COMPLETA_DO_1o_GRAU_(ENSINO_FUNDAMENTAL)'},
    { id: 'POS-GRADUACAO/ESPECIALIZACAO', name: 'POS-GRADUACAO/ESPECIALIZACAO'},
    { id: 'MESTRADO', name: 'MESTRADO'},
    { id: 'ANALFABETO', name: 'ANALFABETO'}
];

export const VULNE_SOCIAL = [
    { id: 'abaixo_25', name: 'Abaixo de 25%' },
    { id: 'entre_25_50', name: 'De 26% à 50%' },
    { id: 'entre_50_75', name: 'De 50% à 75%' },
    { id: 'acima_75', name: 'Acima de 75%' }
];


// COMPORTAMENTAL
export const GENERATIONS = [
    { id: 'baby_boomers', name: 'Baby Boomers (nascidos entre 1946 e 1964)' },
    { id: 'generation_X', name: 'Geração X (1965-1980)' },
    { id: 'generion_Y', name: 'Geração Y ou Millennials (1981-1996)' },
    { id: 'generion_Z', name: 'Geração Z (1997-2010)' },
    { id: 'generion_A', name: 'Há ainda a Geração Alfa, que compreende os nascidos a partir de 2010' },
];

export const ACCOUNT = [
    { id: 'good_payer', name: 'Bom pagador (Regular)' },
    { id: 'pending_payer', name: 'Da uns tropeços (Pendente)' },
    { id: 'bad_payer', name: 'Mal Pagador (Suspenso)' }
]

export const PARTNERS = [
    { id: '1', name: 'SIM' },
    { id: '0', name: 'NÃO' }
];

export const PROFISSAO = [
    { id: 'MECANICO_DE_MANUTENCAO_DE_MAQUINAS_EM_GERAL', name: 'MECANICO_DE_MANUTENCAO_DE_MAQUINAS_EM_GERAL' },
    { id: 'VENDEDOR_DE_COMERCIO_VAREJISTA', name: 'VENDEDOR_DE_COMERCIO_VAREJISTA' },
    { id: 'GARCOM', name: 'GARCOM' },
    { id: 'ELETRICISTA_DE_MANUTENCAO_ELETROELETRONICA', name: 'ELETRICISTA_DE_MANUTENCAO_ELETROELETRONICA' },
    { id: 'CHAPEADOR', name: 'CHAPEADOR' },
    { id: 'PROFESSOR_DE_NIVEL_MEDIO_NO_ENSINO_FUNDAMENTAL', name: 'PROFESSOR_DE_NIVEL_MEDIO_NO_ENSINO_FUNDAMENTAL' },
    { id: 'TECNICO_DE_ENFERMAGEM', name: 'TECNICO_DE_ENFERMAGEM' },
    { id: 'SUPERVISOR_DE_VENDAS_COMERCIAL', name: 'SUPERVISOR_DE_VENDAS_COMERCIAL' },
    { id: 'ASSISTENTE_ADMINISTRATIVO', name: 'ASSISTENTE_ADMINISTRATIVO' },
    { id: 'SUPERVISOR_DE_ALMOXARIFADO', name: 'SUPERVISOR_DE_ALMOXARIFADO' },
    { id: 'VENDEDOR_EM_DOMICILIO', name: 'VENDEDOR_EM_DOMICILIO' },
    { id: 'AUXILIAR_DE_CONTABILIDADE', name: 'AUXILIAR_DE_CONTABILIDADE' },
    { id: 'ALIMENTADOR_DE_LINHA_DE_PRODUCAO', name: 'ALIMENTADOR_DE_LINHA_DE_PRODUCAO' },
    { id: 'GERENTE_DE_VENDAS', name: 'GERENTE_DE_VENDAS' },
    { id: 'INSPETOR_DE_ALUNOS_DE_ESCOLA_PUBLICA', name: 'INSPETOR_DE_ALUNOS_DE_ESCOLA_PUBLICA' },
    { id: 'SUPERVISOR_ADMINISTRATIVO', name: 'SUPERVISOR_ADMINISTRATIVO' },
    { id: 'INSPETOR_DE_SERVICOS_DE_TRANSPORTES_RODOVIARIOS_(PASSAGEIROS_E_CARGAS)', name: 'INSPETOR_DE_SERVICOS_DE_TRANSPORTES_RODOVIARIOS_' },
    { id: 'AUXILIAR_DE_ESCRITORIO_EM_GERAL', name: 'AUXILIAR_DE_ESCRITORIO_EM_GERAL' },
];

export const CATEGORIA_PROFISSAO = [
    { id: 'TRABALHADORES_EM_SERVICOS_DE_REPARACAO_E_MANUTENCAO', name: 'TRABALHADORES_EM_SERVICOS_DE_REPARACAO_E_MANUTENCAO' },
    { id: 'TRABALHADORES_DOS_SERVICOS_VENDEDORES_DO_COMERCIO_EM_LOJAS_E_MERCADOS', name: 'TRABALHADORES_DOS_SERVICOS_VENDEDORES_DO_COMERCIO_EM_LOJAS_E_MERCADOS' },
    { id: 'TRABALHADORES_DA_PRODUCAO_DE_BENS_E_SERVICOS_INDUSTRIAIS', name: 'TRABALHADORES_DA_PRODUCAO_DE_BENS_E_SERVICOS_INDUSTRIAIS' },
    { id: 'TECNICOS_DE_NIVEL_MEDIO', name: 'TECNICOS_DE_NIVEL_MEDIO' },
    { id: 'TRABALHADORES_DE_SERVICOS_ADMINISTRATIVOS', name: 'TRABALHADORES_DE_SERVICOS_ADMINISTRATIVOS' },
    { id: 'MEMBROS_SUPERIORES_DO_PODER_PUBLICO_DIRIGENTES_DE_ORGANIZACOES_DE_INTERESSE_PUBLICO_E_DE_EMPRESAS_GERENTES', name: 'MEMBROS_SUPERIORES_DO_PODER_PUBLICO_DIRIGENTES_DE_ORGANIZACOES_DE_INTERESSE_PUBLICO_E_DE_EMPRESAS_GERENTES' },
    { id: 'PROFISSIONAIS_DAS_CIENCIAS_E_DAS_ARTES', name: 'PROFISSIONAIS_DAS_CIENCIAS_E_DAS_ARTES' },
    { id: 'NAO_INFORMADO', name: 'NAO_INFORMADO' },
    { id: 'MEMBROS_DAS_FORCAS_ARMADAS_POLICIAIS_E_BOMBEIROS_MILITARES', name: 'MEMBROS_DAS_FORCAS_ARMADAS_POLICIAIS_E_BOMBEIROS_MILITARES' },
    { id: 'TRABALHADORES_AGROPECUARIOS_FLORESTAIS_E_DA_PESCA', name: 'TRABALHADORES_AGROPECUARIOS_FLORESTAIS_E_DA_PESCA' },

];

export const CARGO = [
    { id: 'CARGO1', name: 'CARGO1' },
    { id: 'CARGO2', name: 'CARGO2' },
    { id: 'CARGO3', name: 'CARGO3' }
];

export const RENDA = [
    { id: 'ATE_2_SALARIOS', name: 'Até 2 Salários' },
    { id: 'ATE_4_SALARIOS', name: 'Até 4 Salários' },
    { id: 'ATE_10_SALARIOS', name: 'Até 10 Salários' },
    { id: 'ATE_20_SALARIOS', name: 'Até 20 Salários' },
    { id: 'ACIMA_DE_20_SALARIOS', name: 'Acima de 20 Salários' }
];

export const IMOVEIS = [
    { id: 'RENDA1', name: 'RENDA1' },
    { id: 'RENDA2', name: 'RENDA2' },
    { id: 'RENDA3', name: 'RENDA3' }
];

export const CARRO = [
    { id: 'CARRO1', name: 'CARRO1' },
    { id: 'CARRO2', name: 'CARRO2' },
    { id: 'CARRO3', name: 'CARRO3' }
];

export const MARCA_CARRO = [
    { id: 'RENAULT', name: 'RENAULT' },
    { id: 'HONDA', name: 'HONDA' },
    { id: 'FIAT', name: 'FIAT' },
    { id: 'DAFRA', name: 'DAFRA' },
    { id: 'VOLKSWAGEN', name: 'VOLKSWAGEN' },
    { id: 'CHEVROLET', name: 'CHEVROLET' },
    { id: 'YAMAHA', name: 'YAMAHA' },
    { id: 'GM', name: 'GM' },
    { id: 'IMP', name: 'IMP' },
    { id: 'HYUNDAI', name: 'HYUNDAI' },
    { id: 'FORD', name: 'FORD' },
    { id: 'MERCEDES-BENZ', name: 'MERCEDES-BENZ' },
    { id: 'CITROEN', name: 'CITROEN' },
    { id: 'TOYOTA', name: 'TOYOTA' },
    { id: 'MMC', name: 'MMC' },
    { id: 'PEUGEOT', name: 'PEUGEOT' },
    { id: 'KASINSKI', name: 'KASINSKI' },
    { id: 'CTM', name: 'CTM' },
    { id: 'TRIUMPH', name: 'TRIUMPH' },
    { id: 'BMW', name: 'BMW' },
    { id: 'NISSAN', name: 'NISSAN' },
    { id: 'JAC', name: 'JAC' },
    { id: 'JTA', name: 'JTA' },
    { id: 'BRANDY', name: 'BRANDY' },
    { id: 'REB', name: 'REB' },
    { id: 'CHERY', name: 'CHERY' },
    { id: 'QINGQI', name: 'QINGQI' },
    { id: 'LR', name: 'LR' },
    { id: 'IVECO', name: 'IVECO' },
    { id: 'COLINA', name: 'COLINA' },
    { id: 'KIA', name: 'KIA' },
    { id: 'JTA-SUZUKI', name: 'JTA-SUZUKI' },
   
];

export const TROCA_CARRO = [
    { id: 'sim', name: 'SIM' },
    { id: 'nao', name: 'NÃO' },
    { id: 'ambos', name: 'AMBOS' }
];

export const AUXILIO = [
    { id: '1', name: 'Sim' },
    { id: '0', name: 'Não' },
];

// INTERESSE

export const INTERESSE = [                                    
    { id: 'interesse_bebes', name: 'Baby' },
    { id: 'interesse_criptomoedas', name: 'Cryptocoins' },
    { id: 'interesse_automoveis', name: 'Automotive'},
    { id: 'interesse_beleza_saude', name: 'BeautyAndHealth' },
    { id: 'interesse_alimentos_bebidas', name: 'BeverageAndFood' },
    { id: 'interesse_ginastica', name: 'Bodybuilding' },
    { id: 'interesse_livros', name: 'Books' },
    { id: 'interesse_computacao', name: 'Computing' },
    { id: 'interesse_delivery', name: 'Delivery' },
    { id: 'interesse_eletronicos', name: 'Eletronics' },
    { id: 'interesse_entreterimento', name: 'Entertainment' },
    { id: 'interesse_moda', name: 'FashionAndAcessories' },
    { id: 'interesse_games', name: 'Games' },
    { id: 'interesse_educacao', name: 'Education'},
    { id: 'interesse_financeiros', name: 'Finance' },
    { id: 'interesse_servicos_saude', name: 'HealthyService' },
    { id: 'interesse_casa_decoracao', name: 'HomeAndDecoration' },
    { id: 'interesse_eletrodomesticos', name: 'Housewares' },
    { id: 'interesse_celulares', name: 'MobileTelco' },
    { id: 'interesse_instrumentos_musicais', name: 'MusicalInstruments' },
    
    { id: 'interesse_esporte_lazer', name: 'SportAndLeisure' },
    { id: 'interesse_streaming', name: 'Streaming' },
    { id: 'interesse_brinquedos', name: 'Toys' },
    { id: 'interesse_viagem_turismo', name: 'TravelAndTourism' },
    { id: 'Digital', name: 'Digital' },

    // { id: 'Service', name: 'Service' },
    // { id: 'Midias_Sociais', name: 'Midias_Sociais' },
    // { id: 'interesse_mobilidade', name: 'Mobilidade' },
    // { id: 'interesse_mobilidade', name: 'Mobilidade' }
    // 'interesse_restaurantes'
];
