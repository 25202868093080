import { Button, Input } from "antd";
import { CheckCircleTwoTone } from '@ant-design/icons';
import slugify from 'react-slugify';
import { useState } from "react";
import { FilterTag } from "../../../../sdk/@types/filter-tags.model";
import { FILTER_DOCTYPE } from "../../../../sdk/@types/filter.model";
import { v4 as uuidv4 } from "uuid";
import useFilterTags from "../../../../core/hooks/useFilterTags";
import useFilters from "../../../../core/hooks/useFilters";
import useUsers from "../../../../core/hooks/useUsers";

type FilterTagSaveProps = {
    isSave: boolean;
}
export default function FilterTagSave(props: FilterTagSaveProps) {

    const [slug, setSlug] = useState('');
    const [name, setName] = useState('');

    const { state, activeFilterTags, addFilterTags, setSave } = useFilterTags();
    const { fetchFilters } = useFilters();
    
    const { stateUser } = useUsers();


    if (!state.isSave) return null;

    async function onSaveFilterTag() {
        console.log("*** NEW TAG User", stateUser);
        try {
            const newTag: FilterTag = {
                id: uuidv4(),
                userId: stateUser.userAuthenticated.uid,
                teamId: stateUser.userAuthenticated.teamId,
                docType: FILTER_DOCTYPE.TAGS,
                tagName: name,
                tagValue: slug
            }
            console.log("*** NEW TAG", newTag);
            await addFilterTags(newTag);
            await fetchFilters({ teamId: stateUser.userAuthenticated.teamId, filterGroups: slug });
            await activeFilterTags(slug);
            await setSave(false);
        } catch (error) {
            console.log('**** ERRO SAVE FILTERTAG');
        }
        
    }
    
    const onChangeTag = (text: string) => {
        setName(text);
        setSlug(slugify(text));
    }

    return (
        <div style={{ width: '400px', display: 'flex', alignItems: 'center'  }}>
            <Input 
                onChange={(event) => onChangeTag(event.target.value)}
                placeholder="Digite a FilterTag que será o agrupador dos seus filtros" 
            />
            
            <Button 
                style={{ margin: 10 }}
                icon={ <CheckCircleTwoTone 
                    style={{ fontSize: '24px' }} 
                    twoToneColor="#52c41a" />}
                type="link"
                size={"large"}
                onClick={ onSaveFilterTag }
            />
        </div>
    )    
}