
import usePageTitle from "../../core/hooks/usePageTitle";
import { UploadOutlined } from '@ant-design/icons';
import type { UploadProps } from 'antd';
import { Card, Button, message, Upload, Input, Result, Row, Col } from 'antd';
import useUsers from "../../core/hooks/useUsers";
import { useCallback, useEffect, useState } from "react";
import slugify from 'react-slugify';
import { v4 as uuidv4 } from "uuid";
import { FILTER_DOCTYPE } from "../../sdk/@types/filter.model";
import UploadService from "../../sdk/services/upload.service";

export default function UploadView() {
    usePageTitle("Uploads");
    const { stateUser } = useUsers();
    const [tagValue, setTagValue] = useState('');
    const [tagName, setTagName] = useState('');
    const [uploads, setUploads] = useState<any>([]);
    
    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const slug = slugify(e.target.value);
        setTagValue(slug);
        setTagName(e.target.value);
    };
    
    const getItems = useCallback(async () => {
        const result = await UploadService.getAll(stateUser.userAuthenticated.teamId);
        setUploads(result);
    }, [stateUser.userAuthenticated.teamId]);

    const getExtraData: UploadProps['data'] = (file) => ({
        id: uuidv4(),
        userId: stateUser.userAuthenticated.teamId,
        teamId: stateUser.userAuthenticated.teamId,
        docType: FILTER_DOCTYPE.TAGS,
        tagName: tagName,
        tagValue: tagValue,
    });

    const props: UploadProps = {
        name: 'file',
        action: `${process.env.REACT_APP_MS_FILTERS}/uploads`,
        method: "POST",
        showUploadList: true,
        data: getExtraData,
        headers: {
            authorization: 'authorization-text',
        },
        onChange(info) {
            
            console.log('**** STATUS', info.file.status);
            if (info.file.status !== 'uploading') {
                console.log('**** UPLOADING', info.fileList);
            }
            if (info.file.status === 'done') {
                console.log('**** DONE', info.fileList);
                message.success(`${info.file.name} file uploaded successfully`);
                getItems();
            } else if (info.file.status === 'error') {
                console.log('***** INFO ERROR', info);
                message.error(`${info.file.name} file upload failed.`);
            }
            
        },
    };

    useEffect(() => {
        getItems();
    }, [getItems])

  return (
      <>
         <Card title="Enriquecimento de dados" type="inner">
            <div style={{ marginBottom: '15px' }}>
                Faça o upload de suas informaçoes e inicie o processo de enriquecimento.
            </div>
            <div>
                  <Input
                      onChange={onChange}
                      maxLength={100}
                      style={{ width: '350px', marginBottom: '15px' }}
                      placeholder="Digite um nome para o seu publico desejado" />
            </div>
            <Upload {...props}>
                  <Button
                    disabled={tagValue.length <= 10 || !stateUser.userSubscription?.isAuthorization }
                    icon={<UploadOutlined />}>Clique para iniciar o seu Upload</Button>
            </Upload>
          </Card>
          
          <Row gutter={[8, 8]}>
            {uploads.map((item: any, index: number) => {
              return <Col key={index} xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
                        <Result 
                            key={index}
                            status="success"
                            subTitle={`Dado enriquecido pelo team: ${item.teamId}`}
                            title={`Público enriquecido de nome: ${item.filterGroups}`}
                            extra={[
                            <Button type="primary" key="console">
                                Status: { item.status } 
                            </Button>,
                            ]}
                        />      
                    </Col>    
              
            })}
          </Row>
    </>
  );
}
