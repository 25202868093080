import * as CG from "../../CardGrapich.styles";
import { Skeleton, Space } from "antd";
import Meta from "antd/lib/card/Meta";
import { GRAPHIC_TYPES } from "../../../../../sdk/@types/graphic.model";
import useFilters from "../../../../../core/hooks/useFilters";
import { Bar } from "@ant-design/plots";
import { useCallback, useEffect, useState } from "react";

import useGraphicAll from "../../../../../core/hooks/useGraphicAll";
import _ from 'lodash';
// import React from "react";
// import { Chart } from '@antv/g2';
// import ReactDOM from "react-dom";
// import DataSet from "@antv/data-set";

export default function CardGraphicGeneration() {

  const { stateFilters } = useFilters();
  const [config, setConfig ] = useState<any>();
  const { stateGraphicAll } = useGraphicAll();

  const getItems = useCallback(async (items: any) => {
    /**** FILTERS */
    const filters: any = stateFilters.items.find(i => i.type === GRAPHIC_TYPES.GERACAO);  
    const valueStrings: any[] = filters ? filters.valueStrings : [];
    
    /**** MAP */
    let data = (valueStrings.length >= 1) ?
      items.filter((i: any) => valueStrings.includes(i.geracao)) :
      items;
    
    data = data.filter((i: any) => i.geracao);
  
    const result = _(data)
      .groupBy('geracao')
      .map(function(items, geracao) { 
        return { geracao, value: items.length, label: 'Gerações' };
      }).value();

    console.log('*** GERACOES DATA', result);
    // const total = result.reduce((sum, current) => sum + current.value, 0);

    // CHART CONFIG
     const config = {
      data: result,
      xField: 'value',
      yField: 'label',
      seriesField: 'geracao',
      isPercent: true,
      isStack: true,
      xAxis: false,
      label: {
        position: 'middle',
        content: (item: any) => {
          return item.value.toFixed(2);
        },
        style: {
          fill: '#fff',
        },
      },
    };

    setConfig(config);
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);


  // ATUALIZA O COMPONENTE QUANDO ELE É INICIADO E QUANDO A LISTA DE FILTROS É ALTERADA
  useEffect(() => {
    if (stateGraphicAll.items && stateGraphicAll.items.length > 0) {
      getItems(stateGraphicAll.items);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateGraphicAll.items, stateFilters.items]);

  return (
    <>
      <CG.Wrapper>
        <Skeleton loading={!config} avatar active>
          <Space direction="vertical" size="large" style={{ display: "flex" }}>
            <Meta title="Gráfico de Gerações" />
              <Bar {...config} />
          </Space>
        </Skeleton>
      </CG.Wrapper>
    </>
  );
}
