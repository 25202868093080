
export enum SUBSCRIPTION_TYPES {
    FREEMIUM = 'FREEMIUM',
    MENSAL = 'MENSAL',
    SEMESTRAL = 'SEMESTRAL',
    ANUAL = 'ANUAL',
}

export type UserSaaS = {
    uid: string;
    createdAt: number;
    email: string;
    isAdmin: boolean;
    isTeamOwner: boolean;
    name: string;
    teamId: string;
    subscription?: any;
    token?: string;
}

export interface UserSubscription {
  qtdPerson: number;
  isAuthorization: boolean;
  type: string; // FREEMIUM ou MENSAL, SEMESTRAL, ANUAL.
}

export const SUBSCRIPTION_TYPE = [
    { id: 'RUTILO_PRICE_MONTH', value: 'price_1N9UreGNFN2wZ67mGeoKixSy', name: 'Rutilo Mensal', interval: 'month' },
    { id: 'NEXT_PUBLIC_STRIPE_RUTILO_PRICE_SEM', value: 'price_1N9VJCGNFN2wZ67mzDnm75Qi', name: 'Rutilo Semestral', interval: 'semestral' },
    { id: 'NEXT_PUBLIC_STRIPE_RUTILO_PRICE_YEAR', value: 'price_1N9VKaGNFN2wZ67mujzOBNZO', name: 'Rutilo Anual',  interval: 'year'  }
];