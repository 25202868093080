import { Alert, Skeleton, Tabs } from "antd";
import GraphicDemographic from "../features/Graphics/GraphicDemographic/GraphicDemographic";
import usePageTitle from "../../core/hooks/usePageTitle";
import GraphicBehavior from "../features/Graphics/GraphicBehavior/GraphicBehavior";
import GraphicOfficeSalary from "../features/Graphics/GraphicOfficeSalary/GraphicOfficeSalary";
import { useCallback, useEffect, useState } from "react";
import useGraphicAll from "../../core/hooks/useGraphicAll";
import usePersonas from "../../core/hooks/usePersona";
import useUsers from "../../core/hooks/useUsers";
import useFilterTags from "../../core/hooks/useFilterTags";
import ViewStatus from "../components/PersonaStatus/ViewStatus";
import GraphicService from "../../sdk/services/graphic.service";
import NavBarFilter from "../features/NavBar/NavBarFilter";
import useSource from "../../core/hooks/useSource";
import { PersonaQuery } from "../../sdk/@types/pesona.model";

export default function GraphicView() {
  usePageTitle("Gráficos");
  const { setGraphics } = useGraphicAll();
  const { status, statusPersonaDoc } = usePersonas();
  const { stateUser } = useUsers();
  const { state } = useFilterTags();
  const [items, setItems] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const { stateSource } = useSource();

  const onChange = (key: string) => {
    console.log(key);
  };

  const tabs = [
    {
      label: 'Sócio-Demográficas',
      key: '1',
      children: (<GraphicDemographic />)
    },
    {
      label: 'Emprego-Renda',
      key: '2',
      children: (<GraphicOfficeSalary key={2} />)
    },
    {
      label: 'Comportamentais',
      key: '3',
      children: (<GraphicBehavior key={3} />)
    }
  ];
  
  const fetchGraphics = useCallback(
    async function (query: PersonaQuery) {
      try {
        setLoading(true);
        console.log('fetchGraphics');
        const items = await GraphicService.getAll(query);
        setGraphics(items);
        setItems(items);
        setLoading(false);
      } catch (err) {
        setLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
  
  useEffect(() => {
    statusPersonaDoc(stateUser.userAuthenticated.teamId, state.active.tagValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(() => {
    fetchGraphics({
      teamId: stateUser.userAuthenticated.teamId,
      filterGroups: state.active.tagValue,
      isHorus: stateSource.source.isHorus
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.active]);

  return (
    <>
      <NavBarFilter />
      <ViewStatus status={status.statusView} />
      <Skeleton loading={loading} avatar active>
        {items && items.length > 0 ? (
          <Tabs
            onChange={onChange}
            type="card"
            items={tabs}
          />) : (
           <Alert
                message="Eita... não conseguimos desenhar os gráficos desejados com  o público alvo configurado"
                description="Mas, não se preocupe... dê uma passadinha lá em Filtros e tente com novas informações."
                type="info"
                showIcon
                style={{ marginBottom: '15px' }}
            />   
        )}
      </Skeleton>
    </>
  );
}
