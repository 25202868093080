import { AUXILIO, ESCOLARIDADE, ESTADO_CIVIL, GENRES, INTERESSE, MARCA_CARRO, NATURALIDE, PROFISSAO, RENDA } from "../../core/common/items-mock.model"

export type Filter = {
    id: string,
    userId: string,
    teamId: string,
    filterGroups: string[],
    docType: string,
    type: string,
    subType: string,
    group: string,
    field?: string,
    valueNumbers: [number, number],
    valueStrings: string[] | undefined,
    radius?: number,
    lat?: number,
    lng?: number,
    address?: string
}

export type Source = {
    id: string,
    userId: string,
    teamId: string,
    isHorus: boolean
}

export type Person = {
    uid: string,
    userId: string,
    teamId: string,
    filterGroups: string,
    source: string,
    statusPersona: string,
    statusView: string
}

export enum FILTER_DOCTYPE {
    FILTERS = 'filters',
    TAGS = 'tags'
}

export enum PERSONA_STATUS {
    NOTFOUND = 'NOTFOUND',
    CREATING = 'CREATING',
    FINISHED = 'FINISHED'
}

export enum PERSONA_SOURCE {
    HORUS = 'HORUS',
    NOT_HORUS = 'NOT_HORUS',
}

export enum FILTER_TYPES {
    // DEMOGRAFICO
    IDADE = 'IDADE', 
    RANGE = 'RANGE', 
    GENERO = 'GENERO', 
    NATURALIDADE='NATURALIDADE',
    ESTADOCIVIL='ESTADOCIVIL',
    ESCOLARIDADE='ESCOLARIDADE',
    GEOLOCATION='GEOLOCATION',
    VULNESOCIAL='VULNESOCIAL',

    // COMPORTAMENTAL
    GERACAO='GERACAO',
    ACCOUNT = 'ACCOUNT',
    SEGMENTO = 'SEGMENTO',
    PROFISSAO = 'PROFISSAO',
    CARGO = 'CARGO',
    RENDA = 'RENDA',
    AUXILIO = 'AUXILIO',
    IMOVEIS = 'IMOVEIS',
    MARCACARRO = 'MARCACARRO',
    TROCOUCARRO = 'TROCOUCARRO',
    SOCIOS = 'SOCIOS',

    // INTERESSE
    INTERESSE = 'INTERESSE'

}

export enum FILTER_GROUPS {
    DEMOGRAFICO= 'DEMOGRAFICO',
    COMPORTAMENTAL='COMPORTAMENTAL',
    INTERESSE = 'INTERESSE'
}




export const FILTERS = {
    groups: [
        {
            id: FILTER_GROUPS.DEMOGRAFICO,
            //types: [FILTER_TYPES.IDADE, FILTER_TYPES.GENERO
            types: [
                { id: FILTER_TYPES.IDADE, subType: 'range', label: 'Filtro por Idade', value: [0, 18] }, 
                { id: FILTER_TYPES.GENERO, subType: 'multiple', label: 'Filtro por Gênero', value:  [],  items: GENRES  }, 
                { id: FILTER_TYPES.NATURALIDADE, subType: 'multiple', label: 'Nasceu fora do brasil?', value:  [], items: NATURALIDE }, 
                { id: FILTER_TYPES.ESTADOCIVIL, subType: 'multiple', label: 'Filtro por Estado Civil', value: '', items: ESTADO_CIVIL }, 
                { id: FILTER_TYPES.ESCOLARIDADE,subType: 'multiple', label: 'Nível de Escolaridade', value:  FILTER_TYPES.ESCOLARIDADE, items: ESCOLARIDADE }, 
                { id: FILTER_TYPES.GEOLOCATION, subType: 'geolocation', label: 'Onde vive?', value:  { lat: 0, long: 0, radius: 3 } }, 
                // { id: FILTER_TYPES.VULNESOCIAL, subType: 'radio', label: 'Filtro por Vulnerabilidade Social', value:  '', items: VULNE_SOCIAL }
            ]
        },
        {
            id: FILTER_GROUPS.COMPORTAMENTAL,
            types:[
                // { id: FILTER_TYPES.SEGMENTO, subType: 'multiple', label: 'Segmento que trabalha', value:  [], items: CATEGORIA_PROFISSAO }, 
                { id: FILTER_TYPES.PROFISSAO, subType: 'multiple', label: 'Filtro por Profissão', value:  [], items: PROFISSAO }, 
                // { id: FILTER_TYPES.CARGO, subType: 'multiple', label: 'Filtro por Cargo', value:  [], items: CATEGORIA_PROFISSAO }, 
                { id: FILTER_TYPES.RENDA, subType: 'multiple', label: 'Renda mensal média', value:  [], items: RENDA },
                { id: FILTER_TYPES.AUXILIO, subType: 'multiple', label: 'Recebe auxílio Brasil?', value:  [], items: AUXILIO },
                // { id: FILTER_TYPES.IMOVEIS, subType: 'range', label: 'Quantos imóveis próprios', value: [0, 18] }, 
                { id: FILTER_TYPES.MARCACARRO,  subType: 'multiple', label: 'Marca do seu carro', value:  [], items: MARCA_CARRO },  
                // { id: FILTER_TYPES.TROCOUCARRO, subType: 'radio', label: 'Trocou de carro recentemente?', value:  '', items: TROCA_CARRO},
                // { id: FILTER_TYPES.SOCIOS,  subType: 'multiple', label: 'Tem sócios?', value:  '', items: PARTNERS },
                // { id: FILTER_TYPES.GERACAO,  subType: 'radio', label: 'De qual geração faz parte?', value:  '', items: GENERATIONS },
            ]
        },
        {
            id: FILTER_GROUPS.INTERESSE,
            types:[
                { id: FILTER_TYPES.INTERESSE, subType: 'multiple', label: 'Filtro por Interesses', value:  [], items: INTERESSE }, 
            ]
        }
    ]
}