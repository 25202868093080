import { Skeleton, Space } from "antd";
import Meta from "antd/lib/card/Meta";
import { GRAPHIC_TYPES } from "../../../../../sdk/@types/graphic.model";
import useFilters from "../../../../../core/hooks/useFilters";
import { Bar } from "@ant-design/plots";
import { useCallback, useEffect, useState } from "react";
import * as CF from "../../CardGrapich.styles";
import useGraphicAll from "../../../../../core/hooks/useGraphicAll";
import _ from 'lodash';

export default function CardGraphicLevelOfEducation() {

  const { stateFilters } = useFilters();
  const [config, setConfig ] = useState<any>();
  const { stateGraphicAll } = useGraphicAll();

   const getItems = useCallback(async (items: any) => {
    const filters: any = stateFilters.items.find(i => i.type === GRAPHIC_TYPES.ESCOLARIDADE);
    const valueStrings: any[] = filters ? filters.valueStrings : [];
    
    /**** MAP */
    const data = (valueStrings.length >= 1) ?
      items.filter((i: any) => valueStrings.includes(i.escolaridade)) :
      items.filter((i: any) => i.escolaridade);
    
    console.log('*** DATA EDUCATION', data);

    const result = _(data)
      .groupBy('escolaridade')
      .map(function(items, escolaridade) { 
        return { education: escolaridade, frequencia: items.length };
      }).value();

    const total = result.reduce((sum, current) => sum + current.frequencia, 0);
     
    const config = {
      data: result,
      xField: 'frequencia',
      yField: 'education',
      seriesField: 'education',
      xAxis: false,
      legend: {
        position: 'top-left',
      },
      // color: (item: any) => {
      //   return item.education === 'BRASILEIRO' ? '#87f4d0' : '#1890ff'
      // },
      label: {
        position: 'right',
        content: (item: any) => {
          return (( item.frequencia / total) * 100).toFixed(2) + '%' ;
        },
      },
       tooltip: {
         formatter: (datum: any) => {
            return {
              title: null,
              name: 'Escolaridade',
              value: (( datum.frequencia / total) * 100).toFixed(2) + '%',
            };
          },
        },
      };
      setConfig(config)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ATUALIZA O COMPONENTE QUANDO ELE É INICIADO E QUANDO A LISTA DE FILTROS É ALTERADA
  useEffect(() => {
    if (stateGraphicAll.items && stateGraphicAll.items.length > 0) {
      getItems(stateGraphicAll.items);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateGraphicAll.items]);

  return (
    <>
      <CF.Wrapper>
        <Skeleton loading={!config} avatar active>
          <Space direction="vertical" size="large" style={{ display: "flex" }}>
            <Meta title="Gráfico de Escolaridade" />
              <Bar {...config} />
          </Space>
        </Skeleton>
      </CF.Wrapper>
    </>
  );
}
