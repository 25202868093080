import { Card } from "antd";
import styled from "styled-components";

export const Wrapper = styled(Card)`
  background-color: white;
  width: 100%;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  &:hover,
  &:focus {
    color: #09f;
    background-color: #f4f5f7;
    cursor: pointer;
  }
`