import DefaultLayoutSidebar from './Sidebar';
import '../../../index.css';
import React  from 'react';
import { Layout, theme } from 'antd';
import DefaultLayoutHeader from './Header';
import useUsers from '../../../core/hooks/useUsers';
const { Content } = Layout;

interface DefaultLayoutProps {
  children: React.ReactNode;
}

export default function DefaultLayout(
  props: DefaultLayoutProps
) {

  const { stateUser } = useUsers();

  const {
    token: { colorBgContainer },
  } = theme.useToken();
  
  if (!stateUser.userAuthenticated) {
    return (
      <Layout>
      <Layout className="site-layout">
        <Content
          style={{
            margin: '16px',
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          {props.children}
        </Content>
      </Layout>
    </Layout>
    )
  }

  return (
    <Layout>
      <DefaultLayoutSidebar />
      <Layout className="site-layout">
        <DefaultLayoutHeader />
        <Content
          style={{
            margin: '16px',
            // padding: 24,
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          {props.children}
        </Content>
      </Layout>
    </Layout>
    // <Layout>
    //   <DefaultLayoutHeader />
    //   <Layout id={'PageLayout'}>
    //     <DefaultLayoutSidebar />
    //     <Layout style={{ padding: '0 24px 24px' }}>
    //       <Breadcrumb />
    //       <DefaultLayoutContent>
    //         {props.children}
    //       </DefaultLayoutContent>
    //     </Layout>
    //   </Layout>
    // </Layout>
  );
}
