import { Button, Skeleton, Slider, Space } from "antd";
import { useEffect, useState } from "react";
import * as CF from "../CardFilter.styles";
import { WrapperSlider } from "../../WrapperSlider.styles";
import { Filter } from "../../../../sdk/@types/filter.model";
import { CloseCircleTwoTone } from "@ant-design/icons";
import useFilters from "../../../../core/hooks/useFilters";
import withBoundary from "../../../../core/hoc/withBoundary";

type CardFilterRangeProps = {
  isShow: boolean;
  filter: Filter;
  configType: any;
};

function CardFilterRange(props: CardFilterRangeProps) {
  const [loading, setLoading] = useState(true);
  const [range, setRange] = useState(props.filter.valueNumbers);
  const { updateFilter, deleteFilter } = useFilters();

  useEffect(() => {
    setLoading(false);
  }, []);

  const onChange = (value: any) => {
    setRange(value);
  };

  const onAfterChange = async (value: any) => {
    console.log("onAfterChange: ", value);
    let payload: Filter = {
      ...props.filter,
      lat: 0,
      lng: 0,
      radius: 0,
      valueNumbers: value ?? [15, 75],
    };
    await updateFilter(payload);
  };

  const onCancel = async () => {
    await deleteFilter(props.filter.id);
  };

  if (!props.isShow) {
    return <div></div>;
  }

  return (
    <>
      <CF.Wrapper>
        <Skeleton loading={loading} avatar active>
          <Space direction="vertical" size="large" style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h4>{props.configType?.label}</h4>
              <div>
                <Button
                  icon={
                    <CloseCircleTwoTone
                      style={{ fontSize: "24px" }}
                      twoToneColor="#ef3535"
                    />
                  }
                  type="link"
                  size={"large"}
                  onClick={onCancel}
                />
              </div>
            </div>

            <WrapperSlider>
              <Slider
                className="slider-main-div"
                min={0}
                max={100}
                onAfterChange={onAfterChange}
                onChange={onChange}
                range={true}
                defaultValue={props.filter.valueNumbers}
                value={range}
              />
            </WrapperSlider>
          </Space>
        </Skeleton>
      </CF.Wrapper>
    </>
  );
}

export default withBoundary(CardFilterRange, 'CardFilterRange');