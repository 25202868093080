import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import selectSource, * as SourceAction from "../store/sourceSlice";
import { Source } from "../../sdk/@types/filter.model";

export default function useSource() {
  
  const dispatch = useDispatch();
  const stateSource = useSelector(selectSource);

  const setSource = useCallback(
    async function (source: Source) {
      return dispatch(SourceAction.setSource(source));
    },[dispatch]
  );

  const changeHorus = useCallback(
    async function (horus: Source) {
      return dispatch(SourceAction.changeHorus(horus));
    },[dispatch]
  );

  return {
    stateSource,
    setSource,
    changeHorus
  };
}
