import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FilterTag, QueryHorus } from "../../sdk/@types/filter-tags.model";
import selectFilterTags from "../selectors/selectFilterTags";
import * as FilterTagsAction from "../store/filterTagsSlice";

export default function useFilterTags() {
  
  const dispatch = useDispatch();
  const state = useSelector(selectFilterTags);

  const fetchFilterTags = useCallback(
    async function (teamId: string) {
      dispatch(FilterTagsAction.fetchFilterTags(teamId));
    },
    [dispatch]
  );

   const fetchFilterTagsBySlug = useCallback(
    async function (slug: string) {
      dispatch(FilterTagsAction.fetchFilterTagsBySlug(slug));
    },
    [dispatch]
   );
  
  const fetchFilterTagsByHorus = useCallback(
    async function (query: QueryHorus) {
      dispatch(FilterTagsAction.fetchFilterTagsByHorus(query));
    },
    [dispatch]
  );

  const addFilterTags = useCallback(
    async function (item: FilterTag) {
      return dispatch(FilterTagsAction.addFilterTags(item));
    },
    [dispatch]
  );

  const deleteFilterTags = useCallback(
    async function (tag: string) {
      return dispatch(FilterTagsAction.deleteFilterTags(tag));
    },
    [dispatch]
  );


  const activeFilterTags = useCallback(
    async function (slug: string) {
      return dispatch(FilterTagsAction.activeFilterTags(slug));
    },
    [dispatch]
  );

  const setSave = useCallback(
    async function (save: any) {
      return dispatch(FilterTagsAction.setSave(save));
    },[dispatch]
  );

  return {
    state,
    setSave,
    fetchFilterTags,
    addFilterTags,
    activeFilterTags,
    deleteFilterTags,
    fetchFilterTagsBySlug,
    fetchFilterTagsByHorus
  };
}
