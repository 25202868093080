import { PERSONA_STATUS } from "../../../sdk/@types/filter.model";
import { Alert } from "antd";

export interface ViewStatusProps {
    status: string;
}

export default function ViewStatus(props: ViewStatusProps) {
    return (
        <>
            {props.status !== PERSONA_STATUS.FINISHED && (
                <Alert
                    message={ props.status === PERSONA_STATUS.CREATING ? "Dados sendo preparados" : "Dados sendo atualizados" }
                    description={ props.status === PERSONA_STATUS.CREATING ? "Aguarde um momentinho que os dados já serão gerados por nossa IA." : "Aguarde um momentinho que os dados já serão atualizados por nossa IA" }
                    type="warning"
                    showIcon
                    style={{ marginBottom: '15px' }}
                />         
            )}
        </>
    )
    
    
}
