import { FilterQueryAge, FilterQueryGenre, FilterQueryValueStrings } from "../@types/filter-query.model";
import { GRAPHIC_TYPE_API, Graphic } from "../@types/graphic.model";
import { PersonaQuery } from "../@types/pesona.model";
import Service from "../Service";

class GraphicService extends Service {
    
  static getAll(payload: PersonaQuery): Promise<any[]> {
     return this.HttpFilter
      .post<Graphic[]>(`/graphics/all`, payload)
      .then(this.getData)
  }

  static getAge(payload: FilterQueryAge): Promise<any[]> {
    return this.HttpFilter
      .post<Graphic[]>(`/graphics/age`, payload)
      .then(this.getData)
  }

  static getGenre(payload: FilterQueryGenre, type: GRAPHIC_TYPE_API): Promise<any[]> {
    return this.HttpFilter
      .post<Graphic[]>(`/graphics/${type}`, payload)
      .then(this.getData)
  }

  static getWithValueStrings(payload: FilterQueryValueStrings, type: GRAPHIC_TYPE_API): Promise<any[]> {
    return this.HttpFilter
      .post<Graphic[]>(`/graphics/${type}`, payload)
      .then(this.getData)
  }

  static findBy (id: number) {
    return this.HttpFilter
      .get<Graphic>(`/graphics/${id}`)
      .then(this.getData)
  }

  static create (post: Graphic) {
    return this.HttpFilter
      .post<Graphic>('/graphics', post)
      .then(this.getData)
  }

  static update (id: number, graphic: Graphic) {
    return this.HttpFilter
      .put<Graphic>(`/graphics/${id}`, graphic)
      .then(this.getData)
  }

  static search (userId: string, filterGroups: string) {
    console.log('*** ENTROU EM SEARCHGRAPHICS');
    return this.HttpFilter
      .post<Graphic>(`/graphics/search`, { userId, filterGroups })
      .then(this.getData)
  }

}

export default GraphicService