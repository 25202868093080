import styled from "styled-components";

export const Wraper = styled.div<{ color: any}>`
    border-radius: 6px;
    width: 100%;
    height: 80px;
    background-image: linear-gradient(to right, ${p => p.color.color1},  ${p => p.color.color2});
    padding: 10px;
    margin: 5px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    color: white;
`

export const Header = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`

export const Footer = styled.div`
    width: 100%;
    margin-top: 10px;
    font-style: italic;
    text-align: left;
    p {
        font-size: 0.6rem;
        color: white;
    }
`

export const Info = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    
    h1 {
        color: white;
        font-weight: bolder;
        font-size: 0.8rem;
        padding: 0px;
        margin: 0px;
    }

    h2 {
        color: white;
        font-size: 0.7rem;
        padding: 0px;
        margin: 0px;
    }
`

