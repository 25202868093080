import { createAsyncThunk, createReducer, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import { PersonaQuery, PersonaType } from "../../sdk/@types/pesona.model";
import PersonaService from "../../sdk/services/persona.service";

interface PersonaSliceState {
  fetching: boolean;
  count: number;
  items: PersonaType[];
  search?: any;
}

const initialState: PersonaSliceState = {
  fetching: true,
  count: 0,
  items: []
};

export interface PersonaParams {
  teamId: string;
  filterGroups: string;
}

export const searchPersona = createAsyncThunk(
  "personas/searchPersona",
  async function (params: PersonaQuery, { dispatch, rejectWithValue }): Promise<any> {
    try {
      console.log('PERSONA SLICE',params)
      return await PersonaService.search(params);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const personaReducer = createReducer(initialState, (builder) => {

  // const rejectedActions = isRejected(searchPersona);
  // const pendingActions = isPending(searchPersona);
  // const fullFilledActions = isFulfilled(searchPersona);

  builder
    .addCase(searchPersona.fulfilled, (state, action) => {
      state.search = action.payload
    })    
    .addMatcher(isPending, (state) => {
      state.items = [];
      state.fetching = true;
    })
    .addMatcher(isFulfilled, (state) => {
      state.fetching = false;
    })
    .addMatcher(isRejected, (state) => {
      state.items = [];
      state.fetching = false;
    });
});
