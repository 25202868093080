import { SUBSCRIPTION_TYPES } from "../../../sdk/@types/auth.model";

type SubProps = {
    subscription: any;
}
export default function SubscriptionStatus(props: SubProps) {
    if (!props.subscription) { return null; }
    if (!props.subscription.type) { return null; }
    return (
        <>
            { props.subscription.type === SUBSCRIPTION_TYPES.FREEMIUM ? (
                <div style={{ 
                    backgroundColor: '#8aa6e8',
                    borderRadius: '5px',
                    padding: '5px',
                    color: 'white',
                    justifyContent: "center",
                    textAlign: "center",
                    
                }}>
                    {props.subscription.type}<br />
                    Personas: { props.subscription.qtdPerson }
                </div>
            ) : (
                <>
                    <div style={{ 
                        backgroundColor: '#84f3c3',
                        borderRadius: '5px',
                        padding: '5px',
                        
                        justifyContent: "center",
                        textAlign: "center",
                        
                    }}>
                        {props.subscription.type}<br />
                    </div>
                        <div
                            style={{     
                                padding: '5px',
                                justifyContent: "center",
                                textAlign: "center",
                            }}
                        >
                            Personas: {props.subscription.qtdPerson}
                        </div>
                </>
            )}
        </>
    )
}