import { Button, Skeleton, Slider, Space } from "antd";
import { useEffect, useState } from "react";
import * as CF from "../CardFilter.styles";
import Autocomplete from "react-google-autocomplete";
import { WrapperSlider } from "../../WrapperSlider.styles";
import { Filter } from "../../../../sdk/@types/filter.model";
import useFilters from "../../../../core/hooks/useFilters";
import { CloseCircleTwoTone } from "@ant-design/icons";
import withBoundary from "../../../../core/hoc/withBoundary";

type CardFilterGeoProps = {
  isShow: boolean;
  filter: Filter;
  configType: any;
};

function CardFilterGeo(props: CardFilterGeoProps) {

  const [geo, setGeo] = useState({ lat: props.filter.lat, lng: props.filter.lng });
  const [radius, setRadius] = useState(0);
  const [address, setAddress] = useState("");

  const { updateFilter, deleteFilter } = useFilters();

  useEffect(() => {
    setGeo({ lat: props.filter.lat, lng: props.filter.lng })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!props.isShow) {
    return <div></div>;
  }

  const onChangePlace = async (place: any) => {
    setGeo({
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    });
    setAddress(place.formatted_address);

    const radiusStr: any = props.filter.radius?.toString();
    let payload: Filter = {
      ...props.filter,
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
      radius: parseFloat(radiusStr),
      address: place.formatted_address,
    };
    console.log('PLACE UPDATE', payload)
    await updateFilter(payload);
  };

  const onChangeSlider = async (value: number) => {
    console.log('RADIUS', radius);
    setRadius(value);
    const latStr: any = geo.lat?.toString();
    const lngStr: any = geo.lng?.toString();
    let payload: Filter = {
      ...props.filter,
      address,
      lat: parseFloat(latStr),
      lng: parseFloat(lngStr),
      radius: value,
    };
    console.log('RADIUS UPDATE ADDRESS', address);
    console.log('RADIUS UPDATE', payload)
    await updateFilter(payload);
  };

  const onCancel = async () => {
    await deleteFilter(props.filter.id);
  };

  const onErroCapture = (event: any) => {
    console.log('**** ERRO', event);
  };

  return (
    <>
      <CF.Wrapper>
        {/* <Skeleton loading={loading} avatar active> */}
        <Skeleton loading={false} avatar active>
          <Space
            direction="vertical"
            size="large"
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h4>{props.configType?.label}</h4>
              <div>
                <Button
                  icon={
                    <CloseCircleTwoTone
                      style={{ fontSize: "24px" }}
                      twoToneColor="#ef3535"
                    />
                  }
                  type="link"
                  size={"large"}
                  onClick={onCancel}
                />
              </div>
            </div>
            <Autocomplete
              apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
              defaultValue={props.filter.address}
              style={{ width: "90%", height: "30px"}}
              onPlaceSelected={(place) => onChangePlace(place)}
              options={{
                types: ["(regions)"],
                componentRestrictions: { country: "br" },
              }}
              onErrorCapture={onErroCapture}
            />
            <WrapperSlider>
              <h4>Defina o raio de alcance em Km</h4>
              <Slider
                tooltipVisible={true}
                tooltipPlacement={"bottom"}
                max={120}
                defaultValue={props.filter.radius}
                onAfterChange={(value: number) => onChangeSlider(value)}
              />
            </WrapperSlider>
          </Space>
        </Skeleton>
      </CF.Wrapper>
    </>
  );
}

export default withBoundary(CardFilterGeo, 'CardFilterGeo');