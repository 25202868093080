import { Alert, Button, Card, Checkbox, Col, Form, Input, Row } from "antd";
import usePageTitle from "../../core/hooks/usePageTitle";
import userPng from '../../assets/logo_ground.png';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../sdk/firebase/UserAuthContext";
import useUsers from "../../core/hooks/useUsers";
import { useState } from "react";
import { UserSaaS } from "../../sdk/@types/auth.model";

function LoginView() {
    usePageTitle("Login");
    const { activeUser, getUser } = useUsers();
    const { signIn } = useAuth();
    const [error, setError] = useState('');
    const navigate = useNavigate();
    
    const onFinish = async (values: any) => {
        try {
            const result = await signIn(values.username, values.password);
            const user: UserSaaS = await getUser(result.user.uid);
            await activeUser(user);
            navigate(`/home?teamId=${user.teamId}&userId=${user.uid}`);
            console.log('**** LOCATION REPLACE', window.location);
            // window.location.replace(`${window.location.origin}?teamId=${user.teamId}&userId=${user.uid}`);
            console.log('**** LOGIN USER', result);
        } catch (err: any) {
            if (err.message.indexOf("auth/wrong-password") > -1) {
                setError("Senha inválida!");
            }

            if (err.message.indexOf("auth/user-not-found") > -1) {
                setError("Usuário inválido!");
            }
            console.log('**** LOGIN ERROR', err);
        }
        
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <Row justify="space-around" align="middle">
                <Col> 
                    <Card
                        style={{ maxWidth: "600px" }}
                        cover={
                        <img
                            alt="example"
                            src={userPng}
                        />
                        }
                    >
                        {error.length > 0 && (<Alert style={{ marginBottom: '20px' }} message={error} type="error" />)}
                        <Form
                            name="basic"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 16 }}
                            style={{ maxWidth: 600 }}
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item
                            label="Username"
                            name="username"
                            rules={[{ required: true, message: 'Please input your username!' }]}
                            >
                            <Input />
                            </Form.Item>

                            <Form.Item
                            label="Password"
                            name="password"
                            rules={[{ required: true, message: 'Please input your password!' }]}
                            >
                            <Input.Password />
                            </Form.Item>

                            <Form.Item name="remember" valuePropName="checked" wrapperCol={{ offset: 8, span: 16 }}>
                            <Checkbox>Remember me</Checkbox>
                            </Form.Item>

                            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </>
        
  );
}

export default LoginView;
