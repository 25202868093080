import { Card, Col, Row, Space, Spin } from "antd";
import * as PW from './PersonaCardMain.styles';
import { PersonaType } from "../../../sdk/@types/pesona.model";

type PersonaCardMainProps = {
    width: number | string;
    imgGround: string;
    height: number | string;
    persona: PersonaType;
}

export default function PersonaCardMain(props: PersonaCardMainProps) {

    return (
        <Card
            style={{ width: '100%', borderRadius: 10,overflow: "hidden" }}
            cover={<PW.Header imgGround={props.imgGround} />}
            bodyStyle={{ padding: "0", margin: "0" }}
        >
            <PW.WrapperAvatar>
                <PW.Avatar src={props.persona.image} />
            </PW.WrapperAvatar>
            <PW.Body>
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    <Row justify="center" gutter={16}>
                        <Col span={3}>
                            <PW.Title>Idade</PW.Title>
                            <PW.SubTitle>{ props.persona?.age } </PW.SubTitle>
                        </Col>
                        <Col span={6}>
                            <PW.Title>Educação</PW.Title>
                            <PW.SubTitle>{ props.persona?.education }</PW.SubTitle>
                        </Col>
                        <Col span={3}>
                            <PW.Title>IVS</PW.Title>
                            <PW.SubTitle>{ props.persona?.ivs?.toFixed(0) }</PW.SubTitle>
                        </Col>
                    </Row>
                    <Row justify="center" gutter={16}>
                        <Col span={6}>
                            <PW.Title>{props.persona.fullName}</PW.Title>
                            <PW.SubTitle>{props.persona.location?.city} , { props.persona.location?.state} </PW.SubTitle>
                        </Col>
                    </Row>
                    <Row justify="center" gutter={16}>
                        <Col span={22}>
                            <PW.TextTitle>Quem é</PW.TextTitle>
                            <PW.TextBody>
                                {props.persona.texto
                                    ? props.persona.texto
                                    : <div className="example">
                                        <Spin />
                                    </div>
                                }
                            </PW.TextBody>
                        </Col>
                    </Row>
                    <Row justify="center" gutter={16}>
                        <Col span={22}>
                            <PW.TextTitle>Como se comunica</PW.TextTitle>
                            <PW.TextBody>
                            { props.persona?.fullName} faz parte dos { props.persona?.generation?.name }, para gerar maior identificação com ele é importante levar em conta que ele { props.persona.generation?.text }
                            </PW.TextBody>
                        </Col>
                    </Row>
                </Space>
                </PW.Body>
        </Card>
    )
}