import { Col, Dropdown, Menu, Row, Space } from 'antd'
import type { MenuProps } from 'antd';
import { Filter, FILTERS, FILTER_DOCTYPE, FILTER_GROUPS, FILTER_TYPES } from '../../../../sdk/@types/filter.model';
import CardFilterMultiple from '../../../components/FilterCards/CardFilterMultiple/CardFilterMultiple';
import CardFilterRadio from '../../../components/FilterCards/CardFilterRadio/CardFilterRadio';
import * as GB from './GroupBehavior.styles'
import CardFilterRange from '../../../components/FilterCards/CardFilterRange/CardFilterRange';
import useFilters from '../../../../core/hooks/useFilters';
import { v4 as uuidv4 } from "uuid";
import useFilterTags from '../../../../core/hooks/useFilterTags';
import useUsers from '../../../../core/hooks/useUsers';
import useTables from '../../../../core/hooks/useTables';

type GroupBehariorProps = {
    items: Filter[] | undefined;
}

export default function GroupBeharior(props: GroupBehariorProps) {
    const { addFilter } = useFilters();
    const { state } = useFilterTags();
    const { stateUser } = useUsers();
    const { stateTables } = useTables();

    const onMenuClick: MenuProps['onClick'] = async (e) => {
        
        let group = FILTERS.groups.find(i => i.id === FILTER_GROUPS.COMPORTAMENTAL);
        let typeFilter: any = group?.types.find(i => i.id === e.key) ;
        console.log('**** TYPE SELECTED',typeFilter );

        if (typeFilter) {
            const item: Filter = {
                id: uuidv4(),
                group: FILTER_GROUPS.COMPORTAMENTAL, 
                type: typeFilter.id,
                subType: typeFilter.subType,
                docType: FILTER_DOCTYPE.FILTERS,
                filterGroups: [state.active.tagValue] ?? [],
                userId: stateUser.userAuthenticated.uid,
                teamId: stateUser.userAuthenticated.teamId,
                field: typeFilter.field,
                lat: typeFilter.lat,
                lng: typeFilter.lng,
                radius: typeFilter.radius ?? 0,
                valueNumbers: typeFilter.valueNumbers ?? [0, 18],
                valueStrings: typeFilter.valueStrings ?? []
            }
            await addFilter(item);
        }
    };

    const menu = (
        <Menu
          onClick={onMenuClick}
        >
            {
                FILTERS.groups.find(i => i.id === FILTER_GROUPS.COMPORTAMENTAL)?.types.map(type => {
                    return <Menu.Item key={type.id} >{type.label}</Menu.Item>
                })
            }
        </Menu>
      );
      
    return (
        <GB.Wrapper>
            <h4>COMPORTAMENTAL</h4>
            <Space
                direction="vertical"
                size="small"
                style={{
                display: 'flex',
                }}
            >
            {props.items &&
                props.items.map((filter) => {  

                    const configType = FILTERS.groups.find(i => i.id === FILTER_GROUPS.COMPORTAMENTAL)?.types.find(x => x.id === filter.type);

                    if (configType?.id === FILTER_TYPES.PROFISSAO) {
                        configType.items = stateTables.profissoes;
                    }
                    if (configType?.id === FILTER_TYPES.CARGO) {
                        configType.items = stateTables.cargos;
                    }
                    if (configType?.id === FILTER_TYPES.RENDA) {
                        configType.items = stateTables.salarios;
                    }
                    if (configType?.id === FILTER_TYPES.MARCACARRO) {
                        configType.items = stateTables.carros;
                    }

                    if (filter.subType === 'range') {
                        return <CardFilterRange 
                                    configType={configType}
                                    key={filter.id} 
                                    isShow={true} 
                                    filter={filter} />
                    }
                    if (filter.subType === 'multiple') {
                        return <CardFilterMultiple
                                        key={filter.id} 
                                        isShow={true} 
                                        filter={filter}
                                        type={filter.type}
                                        configType={configType}
                                />
                    }
                    if (filter.subType === 'radio') {
                        return <CardFilterRadio
                                        key={filter.id} 
                                        isShow={true} 
                                        filter={filter}
                                        type={filter.type}
                                        configType={configType}
                            />
                    } 
                    return <></>
                      
                }        
            )}
            </Space>
            <Row justify='end'>
                <Col>
                    <Dropdown.Button 
                        disabled={ state.active ? false : true }
                        style={{ marginTop: '10px' }} 
                        overlay={menu}>Criar filtro</Dropdown.Button>
                </Col>
            </Row>
            
        </GB.Wrapper>
    )
}