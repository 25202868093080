import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Filter, Person } from '../../sdk/@types/filter.model';
import selectFilters from '../selectors/seletcFilters';
import * as FilterAction from "../store/filterSlice";
import { FilterQuery } from '../../sdk/@types/filter-query.model';
import FilterPersonaService from '../../sdk/services/filter-persona.service';

export default function useFilters() {

  const dispatch = useDispatch();
  const stateFilters = useSelector(selectFilters);
  
  const addFilter = useCallback(
    async function (item: Filter) {
      return dispatch(FilterAction.addFilter(item));
    },
    [dispatch]
  );

  const updatePerson = useCallback(
    async function (item: Person) {
      return FilterPersonaService.update(item.uid, item);
    },[]
  );

  const updateFilter = useCallback(
    async function (item: Filter) {
      return dispatch(FilterAction.updateFilter(item));
    },
    [dispatch]
  );

  const deleteFilter = useCallback(
    async function (id: string) {
      return dispatch(FilterAction.deleteFilter(id));
    },
    [dispatch]
  );

  const fetchFilters = useCallback(
    async function (query: FilterQuery) {
      dispatch(FilterAction.fetchFilters(query));
    },
    [dispatch]
  );

  return {
    fetchFilters,
    addFilter,
    updateFilter,
    deleteFilter,
    updatePerson,
    stateFilters,
    items: stateFilters.items
  };
}
