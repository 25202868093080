import { Col, Row } from "antd";
import { ReactNode } from "react";
import SelectFilter from "../../components/SelectFilter/SelectFilter";
import withBoundary from "../../../core/hoc/withBoundary";

type NavBarMainProps = {
  children?: ReactNode;
  title?: string;
};
function NavBarMain(props: NavBarMainProps) {
  return (
    <Row
      gutter={[8, 8]}
      justify="end"
      style={{
        marginBottom: 10,
        backgroundColor: "#f4f4f4",
        borderRadius: "10",
        padding: "10px",
      }}
    >
      <Col
        span={12}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
        }}
      >
        <SelectFilter />
      </Col>
      <Col
        span={12}
        style={{
          textAlign: "right",
          display: "flex",
          flexDirection: "row",
          justifyContent: "end",
          alignItems: "center",
        }}
      >
        {props.children}
      </Col>
    </Row>
  );
}

export default withBoundary(NavBarMain, 'NavBarMain');