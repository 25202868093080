import { Menu, Layout, Card } from "antd";
import {
  HomeOutlined,
  UsergroupDeleteOutlined,
  FilterOutlined,
  CloudUploadOutlined
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import UserAvatar from "../../components/UserAvatar/UserAvatar";
import userPng from '../../../assets/logo_ground.png';
const { Sider } = Layout;

export default function DefaultLayoutSidebar() {
  const location = useLocation();

  return (
    <Sider
      width={250}
      style={{ backgroundColor: 'white' }}
      breakpoint="lg"
      collapsedWidth="0"
    >
      <Card
        style={{ width: '100%', textAlign: "center"}}
        cover={
          <img
            alt="example"
            src={userPng}
          />
        }
      >
        <UserAvatar />
      </Card>
     
      <Menu
        mode="inline"
        defaultSelectedKeys={[location.pathname]}
        defaultOpenKeys={[location.pathname.split("/")[1]]}
        style={{ height: "100%", borderRight: 0 }}
      >
        <Menu.Item
          key={"/filters"}
          icon={
            <FilterOutlined
              style={{
                fontSize: "20px",
                fontWeight: "bolder",
                color: "#45526c",
              }}
            />
          }
        >
          <Link to={"/filters"}>Filtros</Link>
        </Menu.Item>
        <Menu.Item
          key={"/dashboard"}
          icon={
            <HomeOutlined
              style={{
                fontSize: "20px",
                fontWeight: "bolder",
                color: "#45526c",
              }}
            />
          }
        >
          <Link to={"/dashboard"}>Dashboard</Link>
        </Menu.Item>
        <Menu.Item
          key={"/personas"}
          icon={
            <UsergroupDeleteOutlined
              style={{
                fontSize: "20px",
                fontWeight: "bolder",
                color: "#45526c",
              }}
            />
          }
        >
          <Link to={"/personas"}>Personas</Link>
        </Menu.Item>
        

        <Menu.Item
          key={"/uploads"}
          icon={
            <CloudUploadOutlined
              style={{
                fontSize: "20px",
                fontWeight: "bolder",
                color: "#45526c",
              }}
            />
          }
        >
          <Link to={"/uploads"}>Uploads</Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
}
