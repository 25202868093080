import * as CG from "../../CardGrapich.styles";
import { Skeleton, Space } from "antd";
import Meta from "antd/lib/card/Meta";
import useFilters from "../../../../../core/hooks/useFilters";
import { Gauge } from "@ant-design/plots";
import { useCallback, useEffect, useState } from "react";
import useGraphicAll from "../../../../../core/hooks/useGraphicAll";

export default function CardGraphicHavePartner() {
  
  const { stateFilters } = useFilters();
  const [config, setConfig ] = useState<any>();
  const { stateGraphicAll } = useGraphicAll();

  const getItems = useCallback(async (items: any) => {
    /**** FILTERS */
    
    const data: any[] = items.filter((i: any) => i.cnpj_empregador);
    console.log('****** SOCIO DATAS', data)

    // CHART CONFIG
    const percentDecimal = data.length / 500;
    const percentFormated = `${((data.length / 500) * 100).toFixed(2)}%`;

    console.log('*** PERCENT DECIMAL', percentDecimal);
    console.log('*** PERCENT FORMATED', percentFormated);

    const config: any = {
      percent: percentDecimal,
      range: {
        color: "l(0) 0:#B8E1FF 1:#3D76DD",
      },
      startAngle: Math.PI,
      endAngle: 2 * Math.PI,
      indicator: null,
      statistic: {
        title: {
          offsetY: -36,
          style: {
            fontSize: "36px",
            color: "#4B535E",
          },
          formatter: () => percentFormated,
        },
        content: {
          style: {
            fontSize: "24px",
            lineHeight: "44px",
            color: "#4B535E",
          },
          formatter: () => "Tem Sociedade",
        },
      },
    };

    setConfig(config);
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);


  // ATUALIZA O COMPONENTE QUANDO ELE É INICIADO E QUANDO A LISTA DE FILTROS É ALTERADA
  useEffect(() => {
    if (stateGraphicAll.items && stateGraphicAll.items.length > 0) {
      getItems(stateGraphicAll.items);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateGraphicAll.items, stateFilters.items]);

  return (
    <>
      <CG.Wrapper>
        <Skeleton loading={!config} avatar active>
          <Space
            direction="vertical"
            size="large"
            style={{
              display: "flex",
            }}
          >
            <Meta title="Gráfico de % de Sócio" />
            <Gauge {...config} />
          </Space>
        </Skeleton>
      </CG.Wrapper>
    </>
  );
}
