import { createAsyncThunk, createReducer, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import { FilterTag, QueryHorus } from "../../sdk/@types/filter-tags.model";
import FilterTagsService from "../../sdk/services/filter-tags.service";

interface FilterTagsSliceState {
  fetching: boolean;
  active: any,
  items: FilterTag[];
  isSave: any,
}

const initialState: FilterTagsSliceState = {
  fetching: false,
  items: [],
  active: null,
  isSave: false,
};

export const fetchFilterTags = createAsyncThunk(
  "filter-tags/fetchFilterTags",
  async function (teamId: string): Promise<FilterTag[]> {
    const items: FilterTag[] = await FilterTagsService.getAll(teamId);
    return items;
  }
);

export const fetchFilterTagsBySlug = createAsyncThunk(
  "filter-tags/fetchFilterTagsBySlug",
  async function (slug: string): Promise<FilterTag[]> {
    const items: FilterTag[] = await FilterTagsService.getBySlug(slug);
    return items;
  }
);

export const fetchFilterTagsByHorus = createAsyncThunk(
  "filter-tags/fetchFilterTagsByHorus",
  async function (query: QueryHorus): Promise<FilterTag[]> {
    const items: FilterTag[] = await FilterTagsService.getByHorus(query.teamId, query.isHorus);
    return !items ? [] : items;
  }
);

export const addFilterTags = createAsyncThunk(
  "filter-tags/create",
  async (filterTags: FilterTag) => {
    return FilterTagsService.create(filterTags);
  }
);

export const deleteFilterTags = createAsyncThunk(
  "filter-tags/delete",
  async (tag: string) => {
    console.log('**** Service PAY', tag);
    return FilterTagsService.delete(tag);
  }
);

export const activeFilterTags = createAsyncThunk(
  "filter-tags/active",
  async (slug: string) => {
    const filterTag: FilterTag = await FilterTagsService.findBySlug(slug);
    return filterTag;
  }
);

export const setSave = createAsyncThunk(
  "filter-tags/setsave",
  async (isSave: any) => {
    console.log('**** seTSavve', isSave);
    return isSave;
  }
);


export const filterTagsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(deleteFilterTags.fulfilled, (state, action) => {
      console.log('**** ACTION PAY', action);
      let index = state.items.findIndex(({ tagValue }) => tagValue === action.payload.tagValue);
      state.items.splice(index, 1);
    })
    .addCase(setSave.fulfilled, (state, action) => {
      console.log('**** SET SAVE', action.payload);
      state.isSave = action.payload;
    })
    .addCase(activeFilterTags.fulfilled, (state, action) => {
      state.active = action.payload;
    })
    .addCase(addFilterTags.fulfilled, (state, action) => {
      console.log('**** addFilterTags addCase', action.payload);
      state.items.push(action.payload);
    })
    .addCase(fetchFilterTags.fulfilled, (state, action) => {
      state.items = action.payload;
    })
    .addCase(fetchFilterTagsBySlug.fulfilled, (state, action) => {
      state.items = action.payload;
    })
    .addCase(fetchFilterTagsByHorus.fulfilled, (state, action) => {
      state.items = action.payload;
    })
    .addMatcher(isPending, (state) => {
      state.fetching = true;
    })
    .addMatcher(isFulfilled, (state) => {
      state.fetching = false;
    })
    .addMatcher(isRejected, (state) => {
      state.fetching = false;
    });
});
