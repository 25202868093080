import {
  createAsyncThunk,
  createReducer,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import GraphicService from "../../../sdk/services/graphic.service";
import { PersonaQuery } from "../../../sdk/@types/pesona.model";

interface GraphicAllSliceState {
  fetching: boolean;
  count: number;
  items: any[];
  config: any;
}

const initialState: GraphicAllSliceState = {
  fetching: true,
  count: 0,
  items: [],
  config: {}
};

export const fetchGraphicAll = createAsyncThunk(
  "graphics/all",
  async function (payload: PersonaQuery,  { dispatch, rejectWithValue }): Promise<any> {
    try {
      return await await GraphicService.getAll(payload);
    } catch(err) {
      console.log('**** ERRO GRAPHIC ALL', err);
      return rejectWithValue(err);
    }
  }
);

export const setGraphics = createAsyncThunk(
  "graphics/set",
  async function (graphics: any[],  { dispatch, rejectWithValue }): Promise<any> {
    try {
      // console.log('**** setGraphics STORE', graphics);
      return graphics;
    } catch(err) {
      console.log('**** ERRO GRAPHIC ALL', err);
      return rejectWithValue(err);
    }
  }
);

// export const clearGraphicAll = createAsyncThunk(
//   "graphics/clear",
//   async function (): Promise<Graphic[]> {
//     return [];
//   }
// );


export const graphicAllReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setGraphics.fulfilled, (state, action) => {
      console.log('**** setGraphics ENTROU', action.payload);
      state.items = action.payload;
    })
    .addCase(fetchGraphicAll.fulfilled, (state, action) => {
      state.items = action.payload;
    })
    .addMatcher(isPending, (state) => {
      state.items = [];
      state.fetching = true;
    })
    .addMatcher(isFulfilled, (state) => {
      state.fetching = false;
    })
    .addMatcher(isRejected, (state) => {
      state.items = [];
      state.fetching = false;
    });
});

