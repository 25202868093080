import { Skeleton, Space, Radio, Button } from "antd";
import useFilters from "../../../../core/hooks/useFilters";
import { CloseCircleTwoTone } from "@ant-design/icons";
import { Filter } from "../../../../sdk/@types/filter.model";
import * as CF from "../CardFilter.styles";
import withBoundary from "../../../../core/hoc/withBoundary";

type CardFilterRadioProps = {
  isShow: boolean;
  filter: Filter;
  type: string;
  configType: any;
};

function CardFilterRadio(props: CardFilterRadioProps) {
  const { updateFilter, deleteFilter } = useFilters();

  const changeRadio = async (e: any) => {
    let payload: Filter = {
      ...props.filter,
      lat: 0,
      lng: 0,
      radius: 0,
      valueStrings: [e.target.value] ?? [],
    };
    await updateFilter(payload);
  };

  const onCancel = async () => {
    await deleteFilter(props.filter.id);
  };

  if (!props.isShow) {
    return <div></div>;
  }

  return (
    <>
      <CF.Wrapper>
        {/* <Skeleton loading={loading} avatar active> */}
        <Skeleton loading={false} avatar active>
          <Space
            direction="vertical"
            size="large"
            style={{
              display: "flex",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h4>{props.configType?.label}</h4>
              <div>
                <Button
                  icon={
                    <CloseCircleTwoTone
                      style={{ fontSize: "24px" }}
                      twoToneColor="#ef3535"
                    />
                  }
                  type="link"
                  size={"large"}
                  onClick={onCancel}
                />
              </div>
            </div>
            <Radio.Group
              defaultValue={
                props.filter.valueStrings ? props.filter.valueStrings[0] : ""
              }
              size="small"
              buttonStyle="solid"
            >
              {props.configType &&
                props.configType.items.map((item: any) => {
                  return (
                    <Radio.Button
                      onClick={changeRadio}
                      value={item["id"]}
                      key={item["id"]}
                    >
                      {item["name"]}
                    </Radio.Button>
                  );
                })}
            </Radio.Group>
          </Space>
        </Skeleton>
      </CF.Wrapper>
    </>
  );
}

export default withBoundary(CardFilterRadio, 'CardFilterRadio');