import {
  createAsyncThunk,
  createReducer,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import TablesService from "../../../sdk/services/tables.service";

interface TablesSliceState {
    fetching: boolean;
    count: number;
    profissoes: any[];
    escolaridades: any[];
    cargos: any[];
    salarios: any[];
    carros: any[];
    config: any;
}

const initialState: TablesSliceState = {
  fetching: true,
  count: 0,
  profissoes: [],
  escolaridades: [],
  cargos: [],
  salarios: [],
  carros: [],
  config: {}
};

export const fetchTables = createAsyncThunk(
  "tables/all",
  async function (): Promise<any[]> {
    return await Promise.all([
      TablesService.getEducation(),
      TablesService.getSalary(),
    ]).then(res => {
        console.log('*** RES', res);
        return res;
    })
  }
);



export const tablesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchTables.fulfilled, (state, action) => {
      // state.educations = action.payload;
      console.log('***** TABLE DADOS', action.payload);
      state.profissoes = action.payload[0];
      state.escolaridades = action.payload[1];
      // state.cargos = action.payload[2];
      state.salarios = action.payload[3];
      state.carros = action.payload[4];

    })
    .addMatcher(isPending, (state) => {
      state.fetching = true;
    })
    .addMatcher(isFulfilled, (state) => {
      state.fetching = false;
    })
    .addMatcher(isRejected, (state) => {
      state.fetching = false;
    });
});
