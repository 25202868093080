import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as GraphicAction from "../store/graphics/graphicAll";
import selectGraphicAll from '../selectors/selectGraphicAll';
import { PersonaQuery } from '../../sdk/@types/pesona.model';

export default function useGraphicAll() {

  const dispatch = useDispatch();
  const stateGraphicAll = useSelector(selectGraphicAll);
  
  const fetchGraphicAll = useCallback(
    async function (payload: PersonaQuery) {
      await dispatch(GraphicAction.fetchGraphicAll(payload));
    },
    [dispatch]
  );

  const setGraphics = useCallback(
    async function (items: any[]) {
      // console.log('**** setGraphics Hook', items)
      await dispatch(GraphicAction.setGraphics(items));
    },
    [dispatch]
  );

  return {
    fetchGraphicAll,
    stateGraphicAll,
    setGraphics
  };
}
