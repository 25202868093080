import { FilterQuery } from "../@types/filter-query.model";
import { Person } from "../@types/filter.model";
import Service from "../Service";

class FilterPersonaService extends Service {
    
  static getAll (query: FilterQuery) {
    return this.HttpFilter
      .get<Person[]>(`/persons?teamId=${query.teamId}&filterGroups=${query.filterGroups}`)
      .then(this.getData)
  }

  static findBy (id: string) {
    return this.HttpFilter
      .get<Person>(`/persons/${id}`)
      .then(this.getData)
  }

  static create(post: Person) {
    console.log('*** ADD FILTER', post);
    return this.HttpFilter
      .post<Person>('/persons', post)
      .then(this.getData)
  }

  static update (id: string, person: Person) {
    return this.HttpFilter
      .put<Person>(`/persons/${id}`, person)
      .then(this.getData)
  }

  static delete (id: string) {
    return this.HttpFilter
      .delete<Person>(`/persons/${id}`)
      .then(this.getData)
  }

}

export default FilterPersonaService