import { Switch } from "antd";
import useUsers from "../../../core/hooks/useUsers";
import { useEffect, useState } from "react";
import SourceService from "../../../sdk/services/source.service";
import { Source } from "../../../sdk/@types/filter.model";
import useSource from "../../../core/hooks/useSource";

export default function UserHorus() {
    const { stateUser } = useUsers();
    const { changeHorus } = useSource();
    const [horus, setHorus] = useState(true);
    
     const onSaveHorus = (value: boolean) => {
        console.log("ENTROU EM onSaveHorus", value);
         setHorus(value);
         
         const source: Source = {
             id: `${stateUser.userAuthenticated.teamId}_${stateUser.userAuthenticated.uid}`,
             teamId: stateUser.userAuthenticated.teamId,
             userId: stateUser.userAuthenticated.uid,
             isHorus: value,
         }
         console.log('**** SOURCE', source);
         changeHorus(source);
        // setHorusState(value);
     }
    
    useEffect(() => {
        if (stateUser.userAuthenticated) {
            SourceService.findBy(stateUser.userAuthenticated.teamId, stateUser.userAuthenticated.uid)
                .then(source => {
                    setHorus(source.isHorus);
                }).catch(err => {
                    console.log('ERRO FINDBY', err)
                    setHorus(true);
                });
            
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    return (
        <>
            <div style={{ marginTop: '10px' }}>Pesquisa por:</div>
            <Switch
                style={{ marginRight: '15px' }}
                onChange={onSaveHorus}
                checkedChildren="Horus"
                unCheckedChildren="Meus Dados"
            defaultChecked
            checked={horus}
            />
        </>
        
    )
}