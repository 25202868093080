import { Col, Row } from "antd";
import CardGraphicLevelOfEducation from "../../../components/GraphicsCards/GraphicDemographic/CardGraphicLevelOfEducation/CardGraphicLevelOfEducation";
import CardGraphicMaritalStatus from "../../../components/GraphicsCards/GraphicDemographic/CardGraphicMaritalStatus/CardGraphicMaritalStatus";
import CardGraphicAge from "../../../components/GraphicsCards/GraphicDemographic/CardGraphicAge/CardGraphicAge";
import CardGraphicGenre from "../../../components/GraphicsCards/GraphicDemographic/CardGraphicGenre/CardGraphicGenre";
import CardGraphicNationality from "../../../components/GraphicsCards/GraphicDemographic/CardGraphicNationality/CardGraphicNationality";

export default function GraphicDemographic() {
  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24} md={12}>
          <CardGraphicAge />
        </Col>
        <Col span={24} md={12}>
          <CardGraphicNationality />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24} md={12}>
          <CardGraphicGenre />
        </Col>
        <Col span={24} md={12}>
          <CardGraphicLevelOfEducation />
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24} md={12}>
          <CardGraphicMaritalStatus />
        </Col>
      </Row>
    </>
  );
}
