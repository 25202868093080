import Service from "../Service";

class TablesService extends Service {
    
  static getOffices(text: string): Promise<any[]> {
    return this.HttpFilter
      .get<any[]>(`/tables/office?text=${text}`)
      .then(this.getData)
  }

  static getEducation(): Promise<any[]> {
    return this.HttpFilter
      .get<any[]>(`/tables/education`)
      .then(this.getData)
  }

  static getCargo(text: string): Promise<any[]> {
    return this.HttpFilter
      .get<any[]>(`/tables/cargo?text=${text}`)
      .then(this.getData)
  }


  static getSalary(): Promise<any[]> {
    return this.HttpFilter
      .get<any[]>(`/tables`)
      .then(this.getData)
  }

  static getBrandCar(text: string): Promise<any[]> {
    return this.HttpFilter
      .get<any[]>(`/tables/brandcar?text=${text}`)
      .then(this.getData)
  }



}

export default TablesService