import { Col, Row } from "antd";
import * as GB from "./GraphicBehavior.styles";
import CardGraphicGeneration from "../../../components/GraphicsCards/GraphicBehavior/CardGraphicGeneration/CardGraphicGeneration";

export default function GraphicBehavior() {
  return (
    <GB.Wrapper>
      <Row gutter={[16, 16]}>
        <Col span={24} md={12}>
           <CardGraphicGeneration />
        </Col>
      </Row>
    </GB.Wrapper>
  );
}
