import { Col, Dropdown, Menu, Row, Space } from 'antd'
import type { MenuProps } from 'antd';
import { Filter, FILTERS, FILTER_DOCTYPE, FILTER_GROUPS } from '../../../../sdk/@types/filter.model';
import CardFilterMultiple from '../../../components/FilterCards/CardFilterMultiple/CardFilterMultiple';
import { v4 as uuidv4 } from "uuid";
import * as GI from './GroupInterest.styles'
import CardFilterRange from '../../../components/FilterCards/CardFilterRange/CardFilterRange';
import useFilters from '../../../../core/hooks/useFilters';
import useFilterTags from '../../../../core/hooks/useFilterTags';
import useUsers from '../../../../core/hooks/useUsers';

type GroupInterestProps = {
    items: Filter[] | undefined;
}

export default function GroupInterest(props: GroupInterestProps) {
    const { addFilter } = useFilters();
    const { state } = useFilterTags();
    const { stateUser } = useUsers();

    const onMenuClick: MenuProps['onClick'] = async (e) => {
        
        let group = FILTERS.groups.find(i => i.id === FILTER_GROUPS.INTERESSE);
        let typeFilter: any = group?.types.find(i => i.id === e.key) ;

        const item: Filter = {
            id: uuidv4(),
            group: FILTER_GROUPS.INTERESSE, 
            type: typeFilter.id,
            subType: typeFilter.subType,
            docType: FILTER_DOCTYPE.FILTERS,
            filterGroups: [state.active.tagValue] ?? [],
            userId: stateUser.userAuthenticated.uid,
            teamId: stateUser.userAuthenticated.teamId,
            field: typeFilter.field,
            lat: typeFilter.lat ?? 0,
            lng: typeFilter.lng ?? 0,
            radius: typeFilter.radius ?? 0,
            valueNumbers: typeFilter.valueNumbers ?? [0, 18],
            valueStrings: typeFilter.valueStrings ?? []
        }
        await addFilter(item);
    };

    const menu = (
        <Menu
          onClick={onMenuClick}
        >
            {
                FILTERS.groups.find(i => i.id === FILTER_GROUPS.INTERESSE)?.types.map(type => {
                    return <Menu.Item key={type.id} >{type.label}</Menu.Item>
                })
            }
        </Menu>
      );
      
    return (
        <GI.Wrapper>
            <h4>INTERESSES</h4>
            <Space
                direction="vertical"
                size="small"
                style={{
                display: 'flex',
                }}
            >
            {props.items &&
                props.items.map((filter) => {  
                    const configType = FILTERS.groups.find(i => i.id === FILTER_GROUPS.INTERESSE)?.types.find(x => x.id === filter.type);

                    if (filter.subType === 'range') {
                        return <CardFilterRange 
                                    configType={configType}
                                    key={filter.id} 
                                    isShow={true} 
                                    filter={filter} />
                    }
                    if (filter.subType === 'multiple') {

                        return <CardFilterMultiple
                                        key={filter.id} 
                                        isShow={true} 
                                        filter={filter}
                                        type={filter.type}
                                        configType={configType}
                                />
                    }
                    if (filter.subType === 'only') {
                        // return <CardFilterRadio
                        //                 key={filter.id} 
                        //                 isShow={true} 
                        //                 filter={filter}
                        //                 type={filter.type}
                        //                 configType={configType}
                        //     />
                    } 
                    return <></>
                      
                }        
            )}
            </Space>
            <Row justify='end'>
                <Col>
                    <Dropdown.Button 
                        disabled={ state.active ? false : true }
                        style={{ marginTop: '10px' }} 
                        overlay={menu}>Criar filtro</Dropdown.Button>
                </Col>
            </Row>
            
        </GI.Wrapper>
    )
}