import { Source } from "../@types/filter.model";
import Service from "../Service";

class SourceService extends Service {
    
  static getAll (teamId: string) {
    return this.HttpFilter
      .get<Source[]>(`/sources?teamId=${teamId}`)
      .then(this.getData)
  }

  static findBy (teamId: string, userId: string) {
    return this.HttpFilter
      .get<Source>(`/sources?teamId=${teamId}&userId=${userId}`)
      .then(this.getData)
  }

  static create(post: Source) {
    return this.HttpFilter
      .post<Source>('/sources', post)
      .then(this.getData)
  }

  static update (id: string, payload: Source) {
    return this.HttpFilter
      .put<Source>(`/sources/${id}`, payload)
      .then(this.getData)
  }

  static delete (id: string) {
    return this.HttpFilter
      .delete<Source>(`/sources/${id}`)
      .then(this.getData)
  }

}

export default SourceService