import { Col, Row, Skeleton } from "antd";
import { FILTER_GROUPS } from "../../sdk/@types/filter.model";
import GroupBehavior from "../features/Filters/GroupBehavior/GroupBehavior";
import GroupDemographic from "../features/Filters/GroupDemographic/GroupDemographic";
import GroupInterest from "../features/Filters/GroupInterest/GroupInterest";
import useFilters from "../../core/hooks/useFilters";
import usePageTitle from "../../core/hooks/usePageTitle";
import NavBarFilter from "../features/NavBar/NavBarFilter";
import ErrorBoundary from "../components/Errors/ErrorBoundary";

export default function FilterView() {
  usePageTitle("Filtros");
  const { stateFilters } = useFilters();

  return (
    <>
      <ErrorBoundary>
        <NavBarFilter />
        <Row gutter={[8, 8]}>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
            <Skeleton loading={stateFilters.fetching} avatar active>
              <GroupDemographic
                key={1}
                items={stateFilters.items?.filter(
                  (i: any) => i.group === FILTER_GROUPS.DEMOGRAFICO
                )}
              />
            </Skeleton>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
            <Skeleton loading={stateFilters.fetching} avatar active>
              <GroupBehavior
                key={2}
                items={stateFilters.items?.filter(
                  (i: any) => i.group === FILTER_GROUPS.COMPORTAMENTAL
                )}
              />
            </Skeleton>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }}>
            <Skeleton loading={stateFilters.fetching} avatar active>
              <GroupInterest
                key={3}
                items={stateFilters.items?.filter(
                  (i: any) => i.group === FILTER_GROUPS.INTERESSE
                )}
              />
            </Skeleton>
          </Col>
        </Row>
      </ErrorBoundary>
    </>
  );
}
