import {
  createAsyncThunk,
  createReducer,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";
import { Graphic } from "../../../sdk/@types/graphic.model";
import GraphicService from "../../../sdk/services/graphic.service";
import { FilterQueryAge } from "../../../sdk/@types/filter-query.model";

interface GraphicAgeSliceState {
  fetching: boolean;
  count: number;
  ageList: any[];
  config: any;
}

const initialState: GraphicAgeSliceState = {
  fetching: true,
  count: 0,
  ageList: [],
  config: {}
};

export const fetchGraphicAge = createAsyncThunk(
  "graphics/age",
  async function (payload: FilterQueryAge): Promise<Graphic[]> {
    return await GraphicService.getAge(payload);
  }
);


export const graphicAgeReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchGraphicAge.fulfilled, (state, action) => {
      state.ageList = action.payload;
      state.config = {
        data: state.ageList,
        columnWidthRatio: 1,
        xField: 'idade',
        yField: 'frequencia',
        xAxis: {
          label: {
            autoHide: true,
            autoRotate: false,
          },
        },
        yAxis: false,
        meta: {
          idade: {
            alias: '类别',
          },
          frequencia: {
            alias: '销售额',
          },
        },
        tooltip: {
          formatter: (datum: any) => {
            return {
              title: null,
              name: 'Idade',
              value: `${datum.idade}`,
            };
          },
        },
      };
    })
    .addMatcher(isPending, (state) => {
      state.fetching = true;
    })
    .addMatcher(isFulfilled, (state) => {
      state.fetching = false;
    })
    .addMatcher(isRejected, (state) => {
      state.fetching = false;
    });
});
