import { ReactNode, createContext, useContext, useEffect, useState } from "react";

import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  Auth,
  UserCredential,
  User,
  sendPasswordResetEmail,
} from "firebase/auth";

import { auth } from './firebase-config';
import React from "react";

export interface AuthProviderProps {
  children?: ReactNode
}

export interface UserContextState {
  isAuthenticated: boolean
  isLoading: boolean
  id?: string
}

export const UserStateContext = createContext<UserContextState>(
  {} as UserContextState,
)
export interface AuthContextModel {
  auth: Auth
  user: User | null
  signIn: (email: string, password: string) => Promise<UserCredential>
  signUp: (email: string, password: string) => Promise<UserCredential>
  logOut: () => Promise<void>
  sendPasswordResetEmail?: (email: string) => Promise<void>
  isAuthorization: (qtdPerson: number) => boolean
}

export const AuthContext = React.createContext<AuthContextModel>(
  {} as AuthContextModel,
)

export function useAuth(): AuthContextModel {
  return useContext(AuthContext)
}

export const UserAuthContextProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const [user, setUser] = useState<User | null>(null)
  
  function signUp(email: string, password: string): Promise<UserCredential> {
    return createUserWithEmailAndPassword(auth, email, password)
  }

  function logOut(): Promise<void> {
    return signOut(auth);
  }

  function signIn(email: string, password: string): Promise<UserCredential> {
    return signInWithEmailAndPassword(auth, email, password)
  }

  function isAuthorization(qtdPerson: number): boolean {
    return qtdPerson > 1 ? false : true;
  }
  
  function resetPassword(email: string): Promise<void> {
    return sendPasswordResetEmail(auth, email)
  }

  useEffect(() => {
    //function that firebase notifies you if a user is set
    const unsubsrcibe = auth.onAuthStateChanged((user) => {
      console.log('*** MUDOU USER', user);
      setUser(user);
    })
    return unsubsrcibe
  }, [])

  const values = {
    signUp,
    user,
    signIn,
    logOut,
    resetPassword,
    auth,
    isAuthorization
  }
  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

export const useUserContext = (): UserContextState => {
    return useContext(UserStateContext)
}