import { Alert, Button, Col, Row, theme } from 'antd';
import useFilterTags from '../../../core/hooks/useFilterTags';
import { useEffect } from 'react';
import useUsers from '../../../core/hooks/useUsers';
import { useAuth } from '../../../sdk/firebase/UserAuthContext';
import { LogoutOutlined } from '@ant-design/icons';
import withBoundary from '../../../core/hoc/withBoundary';

function DefaultLayoutHeader() {

  const { stateUser, changeSubscription } = useUsers();
  const { state } = useFilterTags();
  
  const { logOut } = useAuth();
  const { clearUser } = useUsers();
  
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const onLogout = async () => {
    console.log('*** ENTROU EM LOGOUT')
    await logOut();
    clearUser();
  }

  useEffect(() => {
    changeSubscription(stateUser.userAuthenticated?.uid).catch(error => { return error; });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.active]);
  
  return (
    <div
        style={{
            backgroundColor: colorBgContainer,
            margin: '5px 16px 0px 16px',
          }}
    >
      
      <Row gutter={0} align={"middle"} justify={"center"} style={{ padding: '0px', margin: '0px', backgroundColor: 'white' }} >
        <Col flex="auto">
          {!stateUser.userSubscription?.isAuthorization && (    
              <Alert
                message="Você já atingiu a quota permitida para o seu plano. Clique em Renovar Plano para melhorar a sua assinatura"
                type="warning"
                style={{ width: '100%' }}
                showIcon
                action={
                  <Button size="small" type="dashed" href={process.env.REACT_APP_SAAS_URL} target="new">
                    Renovar Plano
                  </Button>
                }
              />  
            
          )}
        </Col>
        <Col flex="200px" style={{ textAlign: "right", alignItems: 'center' }}>
          <Button type="primary" onClick={onLogout} icon={<LogoutOutlined />} />
        </Col>
      </Row>
    </div>
  );
}

export default withBoundary(DefaultLayoutHeader, 'DefaultLayoutHeader');