import axios, { AxiosResponse } from "axios"

const HttpFilter = axios.create();
const HttpPersona = axios.create();

class Service {
  protected static HttpFilter = HttpFilter
  protected static HttpPersona = HttpPersona

  protected static getData = getData
}

function getData<T> (res: AxiosResponse<T>) {
  return res.data
}

HttpFilter.defaults.baseURL = process.env.REACT_APP_MS_FILTERS;
// HttpFilter.defaults.baseURL = 'http://localhost:3002';
HttpPersona.defaults.baseURL = process.env.REACT_APP_MS_PERSONA;

export default Service
