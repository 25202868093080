import { Skeleton, Space } from "antd";
import Meta from "antd/lib/card/Meta";
import { GRAPHIC_TYPES } from "../../../../../sdk/@types/graphic.model";
import useFilters from "../../../../../core/hooks/useFilters";
import { Bar } from "@ant-design/plots";
import { useCallback, useEffect, useState } from "react";
import * as CG from "../../CardGrapich.styles";
import useGraphicAll from "../../../../../core/hooks/useGraphicAll";
import _ from 'lodash';

export default function CardGraphicCarBrand() {

  const { stateFilters } = useFilters();
  const [config, setConfig ] = useState<any>();
  const { stateGraphicAll } = useGraphicAll();

  const getItems = useCallback(async (items: any) => {
    /**** FILTERS */
    const filters: any = stateFilters.items.find(i => i.type === GRAPHIC_TYPES.MARCACARRO);  
    const valueStrings: any[] = filters ? filters.valueStrings : [];
    
    /**** MAP */
    let data = (valueStrings.length >= 1) ?
      items.filter((i: any) => valueStrings.includes(i.marca)) :
      items;
  
    data = data.filter((i: any) => i.marca);

    const result = _(data)
      .groupBy('marca')
      .map(function(items, marca) { 
        return { marca, frequencia: items.length };
      }).value();

    const total = result.reduce((sum, current) => sum + current.frequencia, 0);

    // CHART CONFIG
    const config = {
      data: result,
      xField: 'frequencia',
      yField: 'marca',
      seriesField: 'marca',
      xAxis: false,
      legend: {
        position: 'top-left',
      },
      label: {
        position: 'middle',
        content: (item: any) => {
          return (( item.frequencia / total) * 100).toFixed(2) + '%' ;
        },
      },
       tooltip: {
        formatter: (datum: any) => {
          return {
            title: null,
            name: 'Marca de Carro',
            value: `${datum.marca}`,
          };
        },
      },
    };

    setConfig(config);
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);


  // ATUALIZA O COMPONENTE QUANDO ELE É INICIADO E QUANDO A LISTA DE FILTROS É ALTERADA
  useEffect(() => {
    if (stateGraphicAll.items && stateGraphicAll.items.length > 0) {
      getItems(stateGraphicAll.items);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateGraphicAll.items, stateFilters.items]);

  return (
    <>
      <CG.Wrapper>
        <Skeleton loading={!config} avatar active>
          <Space
            direction="vertical"
            size="large"
            style={{
              display: "flex",
            }}
          >
            <Meta title="Gráfico de Marca do Carro" />
            <Bar {...config} />
          </Space>
        </Skeleton>
      </CG.Wrapper>
    </>
  );
}
