import {
  AUXILIO,
  CARGO,
  CARRO,
  ESCOLARIDADE,
  ESTADO_CIVIL,
  GENRES,
  IMOVEIS,
  INTERESSE,
  NATURALIDE,
  PARTNERS,
  CATEGORIA_PROFISSAO,
  RENDA,
  VULNE_SOCIAL,
} from "../../core/common/items-mock.model";

export enum GRAPHIC_TYPES {
  // DEMOGRAFICO
  IDADE = "IDADE",
  RANGE = "RANGE",
  GENERO = "GENERO",
  NATURALIDADE = "NATURALIDADE",
  ESTADOCIVIL = "ESTADOCIVIL",
  ESCOLARIDADE = "ESCOLARIDADE",
  GEOLOCATION = "GEOLOCATION",
  VULNESOCIAL = "VULNESOCIAL",

  // COMPORTAMENTAL
  GERACAO = "GERACAO",
  ACCOUNT = "ACCOUNT",
  SEGMENTO = "SEGMENTO",
  PROFISSAO = "PROFISSAO",
  CARGO = "CARGO",
  RENDA = "RENDA",
  AUXILIO = "AUXILIO",
  IMOVEIS = "IMOVEIS",
  MARCACARRO = "MARCACARRO",
  TROCOUCARRO = "TROCOUCARRO",
  SOCIOS = "SOCIOS",

  // INTERESSE
  INTERESSE = "INTERESSE",
}


export enum GRAPHIC_TYPE_API {
  // DEMOGRAFICO
  IDADE = "age",
  GENERO = "genre",
  NATURALIDADE = "nation",
  ESTADOCIVIL = "civilstatus",
  ESCOLARIDADE = "education",
  // GEOLOCATION = "GEOLOCATION",

  // COMPORTAMENTAL
  GERACAO = "generation",
  PROFISSAO = "office",
  RENDA = "salaryrange",
  MARCACARRO = "brandcar",
  SOCIOS = "partner"
}

export enum GRAPHIC_GROUPS {
  DEMOGRAFICO = "DEMOGRAFICO",
  COMPORTAMENTAL = "COMPORTAMENTAL",
  INTERESSE = "INTERESSE",
}

export type Graphic = {
  id: string;
  type: string;
  subType: string;
  group: GRAPHIC_GROUPS;
  value: any;
};

export const GRAPHICS = {
  groups: [
    {
      id: GRAPHIC_GROUPS.DEMOGRAFICO,
      //types: [GRAPHIC_TYPES.IDADE, GRAPHIC_TYPES.GENERO
      types: [
        {
          id: GRAPHIC_TYPES.IDADE,
          subType: "range",
          label: "Gráfico por Idade",
          value: [0, 18],
        },
        {
          id: GRAPHIC_TYPES.GENERO,
          subType: "multiple",
          label: "Gráfico por Gênero",
          value: [],
          items: GENRES,
        },
        {
          id: GRAPHIC_TYPES.NATURALIDADE,
          subType: "multiple",
          label: "Gráfico por Naturalidade",
          value: [],
          items: NATURALIDE,
        },
        {
          id: GRAPHIC_TYPES.ESTADOCIVIL,
          subType: "multiple",
          label: "Gráfico por Estado Civil",
          value: "",
          items: ESTADO_CIVIL,
        },
        {
          id: GRAPHIC_TYPES.ESCOLARIDADE,
          subType: "multiple",
          label: "Gráfico por Escolaridade",
          value: GRAPHIC_TYPES.ESCOLARIDADE,
          items: ESCOLARIDADE,
        },
        {
          id: GRAPHIC_TYPES.GEOLOCATION,
          subType: "geolocation",
          label: "Gráfico por Geolocalização",
          value: { lat: 0, long: 0, radius: 3 },
        },
        {
          id: GRAPHIC_TYPES.VULNESOCIAL,
          subType: "only",
          label: "Gráfico por Vulnerabilidade Social",
          value: "",
          items: VULNE_SOCIAL,
        },
      ],
    },
    {
      id: GRAPHIC_GROUPS.COMPORTAMENTAL,
      types: [
        {
          id: GRAPHIC_TYPES.PROFISSAO,
          subType: "multiple",
          label: "Gráfico por Profissão",
          value: [],
          items: CATEGORIA_PROFISSAO,
        },
        {
          id: GRAPHIC_TYPES.CARGO,
          subType: "multiple",
          label: "Gráfico por Cargo",
          value: [],
          items: CARGO,
        },
        {
          id: GRAPHIC_TYPES.RENDA,
          subType: "multiple",
          label: "Gráfico por Renda Mensal",
          value: [],
          items: RENDA,
        },
        {
          id: GRAPHIC_TYPES.AUXILIO,
          subType: "multiple",
          label: "Gráfico por Auxílio Brasil",
          value: [],
          items: AUXILIO,
        },
        {
          id: GRAPHIC_TYPES.IMOVEIS,
          subType: "range",
          label: "Gráfico por Imóveis",
          value: [0, 18],
        },
        {
          id: GRAPHIC_TYPES.MARCACARRO,
          subType: "multiple",
          label: "Gráfico por Marca de Carro",
          value: [],
          items: CARRO,
        },
        {
          id: GRAPHIC_TYPES.TROCOUCARRO,
          subType: "only",
          label: "Gráfico por Troca de Carro",
          value: "",
          items: IMOVEIS,
        },
        {
          id: GRAPHIC_TYPES.SOCIOS,
          subType: "multiple",
          label: "Gráfico por Sócios",
          value: "",
          items: PARTNERS,
        },
      ],
    },
    {
      id: GRAPHIC_GROUPS.INTERESSE,
      types: [
        {
          id: GRAPHIC_TYPES.INTERESSE,
          subType: "multiple",
          label: "Gráfico por Interesses",
          value: [],
          items: INTERESSE,
        },
      ],
    },
  ],
};
