
import usePageTitle from "../../core/hooks/usePageTitle";
import { useAuth } from "../../sdk/firebase/UserAuthContext";
import { useEffect } from "react";

function LogoutView() {
    usePageTitle("Logout");
    const { logOut } = useAuth();
    
    useEffect(() => {
        logOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return null;

}

export default LogoutView;
