import {
  combineReducers,
  configureStore,
  isRejected,
  Middleware,
} from "@reduxjs/toolkit";
import { filterReducer } from "./filterSlice";
import { graphicAgeReducer } from "./graphics/graphicAge";
import { filterTagsReducer } from "./filterTagsSlice";
import { userReducer } from "./userSlice";
import { personaReducer } from "./personaSlice";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";
// import thunk from 'redux-thunk';
import { notification } from "antd";
import { graphicAllReducer } from "./graphics/graphicAll";
import { tablesReducer } from "./tables/tableSlice";
import { sourceReducer } from "./sourceSlice";


const observeActions: Middleware = () => (next) => (action) => {
  if (isRejected(action)) {
    console.log('**** REDUX ERRROR', action);
    notification.error({
      message: action.error.message,
    });
  }

  next(action);
};

const reducers = combineReducers({
  user: userReducer,
  filter: filterReducer,
  graphicAge: graphicAgeReducer,
  graphicAll: graphicAllReducer,
  filterTags: filterTagsReducer,
  persona: personaReducer,
  tables: tablesReducer,
  source: sourceReducer
});

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: false
  }).concat(observeActions),
  
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
