

 export const PROGRESS_COLOR_MOCK: any = [
    { 
        '0%': '#108ee9',
        '100%': '#87d068'
    },
    { 
        '0%': '#ffbc40',
        '100%': '#ffa500'
    },
    { 
        '0%': '#ff4040',
        '100%': '#cc0000'
    }
 ]


 export const BEHAVIOR_COLOR_MOCK: any[] = [
    { 
        color1: '#65cb90',
        color2: '#65cac9'
    },
    { 
        color1: '#e2495f',
        color2: '#e36944'
    },
    { 
        color1: '#ff4040',
        color2: '#cc0000'
    },
    { 
        color1: '#108ee9',
        color2: '#87d068'
    },
 ]