import { createAsyncThunk, createReducer, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import { SUBSCRIPTION_TYPES, UserSaaS, UserSubscription } from "../../sdk/@types/auth.model";

export interface UserSliceState {
  fetching: boolean;
  count: number;
  userAuthenticated: any;
  userSubscription: UserSubscription;
  items: any[];
}

const initialState: UserSliceState = {
  fetching: false,
  count: 0,
  items: [],
  userAuthenticated: null,
  userSubscription: {
    qtdPerson: 1,
    isAuthorization: false,
    type: SUBSCRIPTION_TYPES.FREEMIUM
  }
};

export const activeUser = createAsyncThunk(
  "users/active",
  async (user: UserSaaS) => {
    return user;
  }
);

export const clearUser = createAsyncThunk(
  "users/clearUser",
  async () => {
    return null;
  }
);

export const activeUserSubscription = createAsyncThunk(
  "users/activeUserSubscription",
  async (user: UserSubscription) => {
    return user;
  }
);



export const userReducer = createReducer(initialState, (builder) => {
  builder
    
    .addCase(activeUser.fulfilled, (state, action) => {
      state.userAuthenticated = action.payload;
    })
    .addCase(clearUser.fulfilled, (state, action) => {
      state.userAuthenticated = null;
    })
    .addCase(activeUserSubscription.fulfilled, (state, action) => {
      state.userSubscription = action.payload;
    })
    .addMatcher(isPending, (state) => {
      state.fetching = true;
    })
    .addMatcher(isFulfilled, (state) => {
      state.fetching = false;
    })
    .addMatcher(isRejected, (state) => {
      state.fetching = false;
    });
});
