import { Card, Col, Row } from "antd";
// import { BEHAVIOR_COLOR_MOCK } from "../../../assets/data/progress-colors";
import { PersonaType } from "../../../sdk/@types/pesona.model";
import * as PBH from "./PersonaCardBehavior.styles";
// import { UserOutlined } from "@ant-design/icons";
import { getColor } from "../../../core/utils/persona.formats";

type PersonaCardBehaviorProps = {
  width: number | string;
  avatar: string;
  imgGround: string;
  height: number | string;
  persona: PersonaType;
};

export default function PersonaCardBehavior(props: PersonaCardBehaviorProps) {
  return (
    <Card
      title="COMPORTAMENTOS"
      bordered={false}
      style={{ width: "100%", borderRadius: 10, overflow: "hidden" }}
      bodyStyle={{ padding: "0", margin: "10px" }}
    >
      <Row justify="center" gutter={8}>
        {props.persona.behavior?.map((item: string, index: number) => {
          return (
            <Col span={12} key={index}>
              <PBH.Wraper key={index} color={getColor(index)}>
                <PBH.Header>{item}</PBH.Header>
              </PBH.Wraper>
            </Col>
          );
        })}
      </Row>
    </Card>
  );
}
