import { Skeleton, Space } from "antd";
import Meta from "antd/lib/card/Meta";
import useFilters from "../../../../../core/hooks/useFilters";
import { Bar } from "@ant-design/plots";
import { useCallback, useEffect, useState } from "react";
import * as CF from "../../CardGrapich.styles";
import useGraphicAll from "../../../../../core/hooks/useGraphicAll";
import _ from 'lodash';

export default function CardGraphicNationality() {

  const { stateFilters } = useFilters();
  const [config, setConfig ] = useState<any>();
  const { stateGraphicAll } = useGraphicAll();

  const getItems = useCallback(async (items: any) => {
    /**** FILTERS */
    const filters: any = stateFilters.items.find(i => i.type === 'NATURALIDADE');  
    const valueStrings: any[] = filters ? filters.valueStrings : ['BRASILEIRA', 'OUTROS'];
    
    /**** MAP */
    let data = (valueStrings.length >= 1) ?
      items.filter((i: any) => valueStrings.includes(i.pais_nascimento)) :
      items;
  
    data = data.filter((i: any) => i.pais_nascimento);

    const result = _(data)
      .groupBy('pais_nascimento')
      .map(function(items, pais_nascimento) { 
        return { nation: pais_nascimento, frequencia: items.length };
      }).value();

    const total = result.reduce((sum, current) => sum + current.frequencia, 0);

    // CHART CONFIG
    const config = {
      data: result,
      xField: 'frequencia',
      yField: 'nation',
      seriesField: 'nation',
      xAxis: false,
      legend: {
        position: 'top-left',
      },
      color: (item: any) => {
        return item.nation === 'BRASILEIRO' ? '#87f4d0' : '#1890ff'
      },
      label: {
        position: 'middle',
        content: (item: any) => {
          return (( item.frequencia / total) * 100).toFixed(2) + '%' ;
        },
      },
       tooltip: {
        formatter: (datum: any) => {
          return {
            title: null,
            name: 'Nacionalidade',
            value: `${datum.nation}`,
          };
        },
      },
    };

    setConfig(config);
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);


  // ATUALIZA O COMPONENTE QUANDO ELE É INICIADO E QUANDO A LISTA DE FILTROS É ALTERADA
  useEffect(() => {
    if (stateGraphicAll.items && stateGraphicAll.items.length > 0) {
      getItems(stateGraphicAll.items);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateGraphicAll.items, stateFilters.items]);

  return (
    <>
      <CF.Wrapper>
        <Skeleton loading={!config} avatar active>
          <Space direction="vertical" size="large" style={{ display: "flex" }}>
            <Meta title="Gráfico de Nacionalidade" />
              <Bar {...config} /> 
          </Space>
        </Skeleton>
      </CF.Wrapper>
    </>
  );
}
