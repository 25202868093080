import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as TableActions from "../store/tables/tableSlice";
import { selectTables } from '../selectors/selectTables';

export default function useTables() {

  const dispatch = useDispatch();
  const stateTables = useSelector(selectTables);

  const fetchEducations = useCallback(
    async function () {
      dispatch(TableActions.fetchTables());
    },
    [dispatch]
  );

  return {
    fetchEducations,
    stateTables,
  };
}
