import { createAsyncThunk, createReducer, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import { Source } from "../../sdk/@types/filter.model";
import SourceService from "../../sdk/services/source.service";
import { RootState } from ".";

export default function selectSource(state: RootState) {
  return state.source
}

interface SourceSliceState {
  fetching: boolean;
  source: Source;
}

const initialState: SourceSliceState = {
    fetching: false,
    source: {
        id: '',
        teamId: '',
        userId: '',
        isHorus: true,
    }
};

export const setSource = createAsyncThunk(
  "source/setSource",
  async (source: Source) => {
    console.log('**** seTSavve', source);
    return source;
  }
);


export const changeHorus = createAsyncThunk(
  "source/changeHorus",
  async (payload: Source) => {
    await SourceService.create(payload);
    return payload;
  }
);

export const sourceReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setSource.fulfilled, (state, action) => {
      console.log('**** ACTION PAY', action);
        state.source = action.payload;
    })
    .addCase(changeHorus.fulfilled, (state, action) => {
      console.log('**** changeHorus ACTION PAY', action);
        state.source = action.payload;
    })
    .addMatcher(isPending, (state) => {
      state.fetching = true;
    })
    .addMatcher(isFulfilled, (state) => {
      state.fetching = false;
    })
    .addMatcher(isRejected, (state) => {
      state.fetching = false;
    });
});
