import { FilterQuery } from "../@types/filter-query.model";
import { Filter } from "../@types/filter.model";
import Service from "../Service";

class FilterService extends Service {
    
  static getAll (query: FilterQuery) {
    return this.HttpFilter
      .get<Filter[]>(`/filters?teamId=${query.teamId}&filterGroups=${query.filterGroups}`)
      .then(this.getData)
  }

  static findBy (id: string) {
    return this.HttpFilter
      .get<Filter>(`/filters/${id}`)
      .then(this.getData)
  }

  static create(post: Filter) {
    console.log('*** ADD FILTER', post);
    return this.HttpFilter
      .post<Filter>('/filters', post)
      .then(this.getData)
  }

  static update (filterId: string, filter: Filter) {
    return this.HttpFilter
      .put<Filter>(`/filters/${filterId}`, filter)
      .then(this.getData)
  }

  static delete (id: string) {
    return this.HttpFilter
      .delete<Filter>(`/filters/${id}`)
      .then(this.getData)
  }

  static getOccupations() {
    return this.HttpFilter
      .get<any[]>(`/occupations`)
      .then(this.getData)
  }

}

export default FilterService