import { PersonaQuery, PersonaType } from "../@types/pesona.model";
import Service from "../Service";

class PersonaService extends Service {
    
  static getAll () {
    return this.HttpPersona
      .get<PersonaType[]>('/persona')
      .then(this.getData)
  }

  static findBy (id: number) {
    return this.HttpPersona
      .get<PersonaType>(`/persona/${id}`)
      .then(this.getData)
  }

  static create (post: PersonaType) {
    return this.HttpPersona
      .post<PersonaType>('/persona', post)
      .then(this.getData)
  }

  static update (id: number, persona: PersonaType) {
    return this.HttpPersona
      .put<PersonaType>(`/persona/${id}`, persona)
      .then(this.getData)
  }

  static search(query: PersonaQuery) {
    return this.HttpPersona
      .post<PersonaType>(`/persona`, query)
      .then(this.getData)
  }
}

export default PersonaService