import { Button, Col, Result, Row } from "antd";
import usePageTitle from "../../core/hooks/usePageTitle";
import { useNavigate } from "react-router-dom";

function NotAuthorizedView() {
  usePageTitle("Home");
  const navigate = useNavigate();
    
    const onNav = () => {
        // const url = process.env.REACT_APP_SAAS_URL ?
        //   `${process.env.REACT_APP_SAAS_URL}/login?redirect=rutilo` : process.env.REACT_APP_SAAS_URL;
      // window.location.replace(url || '');
      navigate('/login');
    }

  return (
      <Row align={"middle"}>
        <Col span={24}>
            <Result
                status="403"
                title="403"
                subTitle="Você está sem autorização para acessar o Rutilo. Provavelmente seu token de acesso expirou. Tente realizar o login novamente!"
                extra={<Button type="primary" onClick={onNav}>Ir para Login</Button>}
            />
        </Col>
      </Row>
  );
}

export default NotAuthorizedView;
