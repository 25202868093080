import { Route, Routes } from "react-router-dom";
import HomeView from "./views/Home.view";
import { useEffect } from "react";
import { message, notification } from "antd";
import FilterView from "./views/Filter.view";
import PersonaView from "./views/Persona.view";
import GraphicView from "./views/Graphic.view";
import DefaultLayout from './layouts/Default';
import LoginView from "./views/Login.view";
import UploadView from "./views/Upload.view";
import LogoutView from "./views/Logout.view";
import NotAuthorizedView from "./views/NotAuthorized.view";
import ProtectedRoute from "./views/ProtectedRoute";

export default function Pages() {
  
  useEffect(() => {
    window.onunhandledrejection = ({ reason }) => {
      if (reason) {
        if (reason.data?.objects) {
          reason.data.objects.forEach((error: any) => {
            message.error(error.message);
          });
        } else {
          notification.error({
            message: reason.message,
            description:
              reason.data?.detail === "Network Error"
                ? "Erro na rede"
                : reason.data?.detail,
          });
        }
      } else {
        notification.error({
          message: "Houve um erro",
        });
      }
    };

    return () => {
      window.onunhandledrejection = null;
    };
  }, []);

  return (
      <DefaultLayout>    
          <Routes>
            <Route path="/login" element={<LoginView />} />
            <Route path="/logout" element={<LogoutView />} />
            <Route path="/notauthorized" element={<NotAuthorizedView />} />
            <Route path="*" element={<LoginView />} />
              <Route element={<ProtectedRoute />} >
                <Route path="/" element={<HomeView />} />
                <Route path={"/home"} element={<HomeView />} />
                  <Route path={"/filters"} element={<FilterView />} />
                  <Route path={"/dashboard"} element={<GraphicView />} />
                  <Route path={"/personas"} element={<PersonaView />} />
                  <Route path={"/uploads"} element={<UploadView />} />
              </Route>
          </Routes>
      </DefaultLayout>
  );
}
