import { FilterTag } from "../@types/filter-tags.model";
import Service from "../Service";

class FilterTagsService extends Service {
    
  static getAll (teamId: string) {
    return this.HttpFilter
      .get<FilterTag[]>(`/filter-tags?teamId=${teamId}`)
      .then(this.getData)
  }

  static getBySlug (slug: string) {
    return this.HttpFilter
      .get<FilterTag[]>(`/filter-tags/slug/${slug}`)
      .then(this.getData)
  }

  static getByHorus (teamId: string, isHorus: boolean) {
    return this.HttpFilter
      .get<FilterTag[]>(`/filter-tags/horus?teamId=${teamId}&isHorus${isHorus}`)
      .then(this.getData)
  }

  static findBy (id: string) {
    return this.HttpFilter
      .get<FilterTag>(`/filter-tags/${id}`)
      .then(this.getData)
  }

  static findBySlug (slug: string) {
    return this.HttpFilter
      .get<FilterTag>(`/filter-tags/slug/${slug}`)
      .then(this.getData)
  }

  static create (filterTag: FilterTag) {
    return this.HttpFilter
      .post<FilterTag>('/filter-tags', filterTag)
      .then(this.getData)
  }

  static update (filterId: number, filter: FilterTag) {
    return this.HttpFilter
      .put<FilterTag>(`/filter-tags/${filterId}`, filter)
      .then(this.getData)
  }

  static delete (id: string) {
    console.log('***** TAG DELETE', id)
    return this.HttpFilter
      .delete<FilterTag>(`/filter-tags/${id}`)
      .then(this.getData)
  }
}

export default FilterTagsService