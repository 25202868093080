import { Alert, Col, Row, Skeleton, Space } from "antd";
import { useCallback, useEffect, useState } from "react";
import PersonaCardBehavior from "../components/PersonaCard/PersonaCardBehavior";
import PersonaCardMain from "../components/PersonaCard/PersonaCardMain";
import PersonaCardInterest from "../components/PersonaCard/PersonCardInterest";
import ground from "./../../assets/theme/img-1-1000x600.jpg";
import avatar from "./../../assets/theme/team-4.jpg";
import NavBarMain from "../features/NavBar/NavBarMain";
import useUsers from "../../core/hooks/useUsers";
import useFilterTags from "../../core/hooks/useFilterTags";
import { PERSONA_STATUS } from "../../sdk/@types/filter.model";
import PersonaStatus from "../components/PersonaStatus/PersonaStatus";
import PersonaRequirements from "../components/PersonaRequirements/PersonaRequirements";
import useSource from "../../core/hooks/useSource";
import { doc, getDoc } from "firebase/firestore";
import { db } from '../../sdk/firebase/firebase-config';
import PersonaService from "../../sdk/services/persona.service";

export default function PersonaView() {
  // const { status, statusPersonaDoc } = usePersonas();
  const { stateUser } = useUsers();
  const { state } = useFilterTags();
  const [persona, setPersona] = useState<any>();
  const [loading, setLoading] = useState(false);
  const { stateSource } = useSource();
  // const [status, setStatus] = useState();

  // const statusPersona = useCallback(
  //   async function (teamId: string, filterGroups: string, isHorus: boolean) {
  //     const horus = isHorus ? 'horus' : 'enriquecidos';
  //     const path = `${teamId}_${filterGroups}_${horus}`;
  //     console.log("PATH", path);
  //     return onSnapshot(doc(db, "personas", path), (doc) => {
  //       const result: any = doc.data();
  //       console.log("ON SNAPSHOP onSnapshot: ", { ...result.persona, statusView: result.statusView, statusPersona: result.statusPersona });
  //       if (result) {
  //         // setStatus({ ...result, statusView: result.statusView, statusPersona: result.statusPersona });
  //         setPersona({ ...result.persona, statusView: result.statusView, statusPersona: result.statusPersona });
  //       } else {
  //         // setStatus({...result, statusView: PERSONA_STATUS.CREATING, statusPersona: PERSONA_STATUS.CREATING });
  //         setPersona({...result.persona, statusView: PERSONA_STATUS.CREATING, statusPersona: PERSONA_STATUS.CREATING });
  //       }
  //     });
  //   },[]
  // );

  const fetchPersona = useCallback(
    async function (teamId: string, filterGroups: string, isHorus: boolean) {
      try {
        setLoading(true);
        setPersona(null);
        // ENVIA REQUISICAO ASSINCRONA
        await PersonaService.search({ teamId, filterGroups, isHorus });
        const horus = isHorus ? 'horus' : 'enriquecidos';
        const path = `${teamId}_${filterGroups}_${horus}`;
        console.log("PATH", path);

        const docRef = doc(db, "personas", path);
        const result = (await getDoc(docRef)).data();
        console.log('PERSONA', result?.persona);
        setPersona({ ...result?.persona, statusView: result?.statusView, statusPersona: result?.statusPersona });
        setLoading(false);
      } catch (err) {
        console.log('*** ERRO PERSONA', err);
        setLoading(false);
        setPersona(null);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  useEffect(() => {
    setPersona({ statusView: PERSONA_STATUS.CREATING, statusPersona: PERSONA_STATUS.CREATING });
    // statusPersona(stateUser.userAuthenticated.teamId, state.active.tagValue, stateSource.source.isHorus);
    fetchPersona(stateUser.userAuthenticated.teamId, state.active.tagValue, stateSource.source.isHorus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.active]);
  
  // useEffect(() => {
  //   console.log('**** PERSONA', persona);
  //   fetchPersona(stateUser.userAuthenticated.teamId, state.active.tagValue, stateSource.source.isHorus);
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [state.active]);

  return (
    <>
        <NavBarMain />
        <PersonaRequirements filterGroups={state.active} >
          <PersonaStatus status={ persona?.statusPersona } />
            <Skeleton loading={loading} avatar active>
              {persona?.statusPersona === PERSONA_STATUS.FINISHED && (
                <Row  gutter={16} style={{ marginTop: 10 }}>
                  <Col xs={24} sm={24} md={14}>
                    <Skeleton loading={loading} avatar active>
                      <PersonaCardMain
                        width={"100%"}
                        height={200}
                        imgGround={ground}
                        persona={persona}
                      />
                    </Skeleton>
                  </Col>

                <Col xs={24} sm={24} md={10}>
                  <Space direction="vertical" size="middle" style={{ display: "flex" }}>
                    <Skeleton loading={loading} avatar active>
                      <PersonaCardInterest
                        width={"60%"}
                        height={200}
                        avatar={avatar}
                        imgGround={ground}
                        persona={persona}
                      />
                    </Skeleton>
                    <Skeleton loading={loading} avatar active>
                      <PersonaCardBehavior
                        width={"60%"}
                        height={200}
                        avatar={avatar}
                        imgGround={ground}
                        persona={persona}
                      />
                    </Skeleton>
                  </Space>
                  </Col>
                </Row>
                )
            }
            {!persona && persona && persona.statusPersona !== PERSONA_STATUS.CREATING && (
              <Alert
                    message="Eita... não conseguimos encontrar a Persona desejada com o público alvo definido"
                    description="Mas, não se preocupe... dê uma passadinha lá em Filtros e tente com novas informações."
                    type="info"
                    showIcon
                    style={{ marginBottom: '15px' }}
                  /> 
            )}
          </Skeleton>        
      </PersonaRequirements>

    </>
  );
}
