import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import selectPersonas from '../selectors/selectPersona';
import * as PersonaAction from "../store/personaSlice";
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../../sdk/firebase/firebase-config';
import { PERSONA_STATUS } from '../../sdk/@types/filter.model';
import { PersonaQuery } from '../../sdk/@types/pesona.model';

export default function usePersonas() {

  const dispatch = useDispatch();
  const statePersona = useSelector(selectPersonas);
  const [status, setStatus ] = useState({ statusView: '', statusPersona: '' });
  
  const searchPersona = useCallback(
    async function (query: PersonaQuery) {
      console.log('PERSONA HOOK', query);
      await dispatch(PersonaAction.searchPersona(query));
    },
    [dispatch]
  );  

  const statusPersonaDoc = useCallback(
    async function (teamId: string, filterGroups: string) {
      return onSnapshot(doc(db, "personas", `${teamId}_${filterGroups}`), (doc) => {
        console.log("ON SNAPSHOP onSnapshot: ", doc.data());
        const result: any = doc.data();
        if (result) {
          setStatus({...result, statusView: result.statusView, statusPersona: result.statusPersona });
        } else {
          setStatus({...result, statusView: PERSONA_STATUS.CREATING, statusPersona: PERSONA_STATUS.CREATING });
        }
      });
    },[]
  );  

  return {
    searchPersona,
    statePersona,
    statusPersonaDoc,
    status,
    items: statePersona.items,
  };
}
