import * as CG from "../../CardGrapich.styles";
import { Skeleton, Space } from "antd";
import Meta from "antd/lib/card/Meta";
import { GRAPHIC_TYPES } from "../../../../../sdk/@types/graphic.model";
import useFilters from "../../../../../core/hooks/useFilters";
import { Bar } from "@ant-design/plots";
import { useCallback, useEffect, useState } from "react";
import useGraphicAll from "../../../../../core/hooks/useGraphicAll";
import _ from 'lodash';

export default function CardGraphicProfession() { 
  
  const { stateFilters } = useFilters();
  const [config, setConfig ] = useState<any>();
  const { stateGraphicAll } = useGraphicAll();

  // const acronymn = ( str: any )=> {
  //   return str.split( /\b(?=[a-z])/ig ) // split on word boundaries
  //     .map( (token: any) => token[0] )         // get first letter of each token
  //     .join( '' ).toLowerCase()         // convert to lowercase string
  //   ;
  // }
  
  const getItems = useCallback(async (items: any) => {
    
    /**** FILTERS */
    const filters: any = stateFilters.items.find(i => i.type === GRAPHIC_TYPES.PROFISSAO);  
    const valueStrings: any[] = filters ? filters.valueStrings : [];
    
    /**** MAP */
    let data = (valueStrings.length >= 1) ?
      items.filter((i: any) => valueStrings.includes(i.cargo)) :
      items;
    
    data = data.filter((i: any) => i.cargo);
    console.log('**** PROFISSAO ITEMS', data);

    const result: any[] = _(data)
      .groupBy('cargo')
      .map(function(items, cargo) { 
        return { profissao: cargo.substring(0, 10), frequencia: items.length };
      }).value();

    const total = result.reduce((sum, current) => sum + current.frequencia, 0);

    // CHART CONFIG
    
    const config = {
        data: result.slice(0,5),
        xField: 'frequencia',
        yField: 'profissao',
        seriesField: 'profissao',
        xAxis: false,
        legend: {
          position: 'top-left',
        },
        label: {
          position: 'middle',
          content: (item: any) => {
            return (( item.frequencia / total) * 100).toFixed(2) + '%' ;
          },
        },
        tooltip: {
          formatter: (datum: any) => {
            return {
              title: null,
              name: 'Profissão',
              value: `${datum.profissao}`,
            };
          },
        },
      };
    

    setConfig(config);
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);


  // ATUALIZA O COMPONENTE QUANDO ELE É INICIADO E QUANDO A LISTA DE FILTROS É ALTERADA
  useEffect(() => {
    if (stateGraphicAll.items && stateGraphicAll.items.length > 0) {
      getItems(stateGraphicAll.items);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateGraphicAll.items, stateFilters.items]);

  return (
    <>
      <CG.Wrapper>
        <Skeleton loading={!config} avatar active>
          <Space direction="vertical" size="large" style={{ display: "flex" }}>
            <Meta title="Gráfico de Profissão" />
              <Bar {...config} />
          </Space>
        </Skeleton>
      </CG.Wrapper>
    </>
  );
}
