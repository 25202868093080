import { Result } from "antd";

export interface PersonaRequirementsProps {
    filterGroups: any;
    children: React.ReactNode
}

export default function PersonaRequirements(props: PersonaRequirementsProps) {
    
     if(props.filterGroups) {
        return <>{props.children}</>;
     }
    
    return <Result
            status="warning"
            title="É necessário ao menos um filtro para iniciar a construção de uma Persona."
        />;
    
}
