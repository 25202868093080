import { Card, Col, Progress, Row, Space } from "antd";
// import styled from "styled-components";
// import { isNamedExports } from "typescript";
import { PROGRESS_COLOR_MOCK } from "../../../assets/data/progress-colors";
import { PersonaInterest, PersonaType } from "../../../sdk/@types/pesona.model";
import * as PCI from "./PersonaCardMain.styles";

type PersonaCardInterestProps = {
  width: number | string;
  avatar: string;
  imgGround: string;
  height: number | string;
  persona: PersonaType;
};

export default function PersonaCardInterest(props: PersonaCardInterestProps) {
  const colors = PROGRESS_COLOR_MOCK;
  return (
    <Card
      title="INTERESSES"
      bordered={false}
      style={{ width: "100%", borderRadius: 10, overflow: "hidden" }}
      bodyStyle={{ overflowY: "auto", height: 400 }}
    >
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        {props.persona.interest?.map((item: PersonaInterest, index: number) => {
          return (
            <Row justify="center" gutter={2} key={index}>
              <Col span={24}>
                <PCI.Title>{item.value}</PCI.Title>
                <Progress strokeColor={colors[index]} percent={item.percent} />
              </Col>
            </Row>
          );
        })}
      </Space>
    </Card>
  );
}

// TODO: COMENATADO PARA PASSAR NO BUILD
// const WrapperScroll = styled.div`
//   overflow-y: scroll;
//   height: 300px;
//   width: 100%;
// `;
// FIM DO TODO
