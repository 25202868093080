import { useCallback, useEffect } from "react";
import { Navigate, Outlet, useNavigate, useSearchParams } from "react-router-dom";
import useUsers from "../../core/hooks/useUsers";
import AuthService from "../../sdk/services/auth.service";

const ProtectedRoute = ({ children }: any) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { stateUser, activeUser } = useUsers();

  const validate = useCallback(async () => {
    const userId = searchParams.get('userId');
    if (!stateUser.userAuthenticated && userId) {
      AuthService.authorization(userId)
        .then(res => {
          activeUser(res);
        })
        .catch(err => {
          console.log('***** ERRO', err);
          navigate('/notauthorized');
        });
    }
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    validate();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) 
  
  if (!stateUser.userAuthenticated) {
    return <Navigate to="/login" />;
  }
    return children ? children : <Outlet />;
};

export default ProtectedRoute;