import { PERSONA_STATUS } from "../../../sdk/@types/filter.model";
import { Alert } from "antd";

export interface PersonaStatusProps {
    status: string;
}

export default function PersonaStatus(props: PersonaStatusProps) {
    return (
        <>
            {props.status !== PERSONA_STATUS.FINISHED && (
                <Alert
                    message={ props.status === PERSONA_STATUS.CREATING ? "Persona sendo criada" : "Persona sendo atualizada" }
                    description="Aguarde um momentinho que já já vamos mostrar seu público alvo gerado por nossa IA"
                    type="warning"
                    showIcon
                    style={{ marginBottom: '15px' }}
                />         
            )}
        </>
    )
    
    
}
