import { createAsyncThunk, createReducer, isFulfilled, isPending, isRejected } from "@reduxjs/toolkit";
import { Filter } from "../../sdk/@types/filter.model";
import FilterService from "../../sdk/services/filter.service";
import { FilterQuery } from "../../sdk/@types/filter-query.model";
interface FilterSliceState {
  fetching: boolean;
  count: number;
  items: Filter[];
}

const initialState: FilterSliceState = {
  fetching: false,
  count: 0,
  items: []
};

export const fetchFilters = createAsyncThunk(
  "filters/fetchFilters",
  async function (query: FilterQuery, { rejectWithValue }) {
    try {
      return await FilterService.getAll(query);
    } catch (error) {
      console.log('**** FETCH FILTER ERROR', error);
      return rejectWithValue({error });
    }
    // const result = await FilterService.getAll(query.userId, query.tag);
    // return result;
  }
);

export const addFilter = createAsyncThunk(
  "filters/create",
  async (item: Filter) => {
    return FilterService.create(item);
  }
);

export const updateFilter = createAsyncThunk(
  "filters/update",
  async (item: Filter) => {
    return FilterService.update(item.id, item);
  }
);

export const deleteFilter = createAsyncThunk(
  "filters/delete",
  async (id: string) => {
    await FilterService.delete(id);
    return { id };
  }
);

export const filterReducer = createReducer(initialState, (builder) => {
  
  const rejectedActions = isRejected(fetchFilters);
  const pendingActions = isPending(fetchFilters);
  const fullFilledActions = isFulfilled(fetchFilters);

  builder
    .addCase(deleteFilter.fulfilled, (state, action) => {
      let index = state.items.findIndex(({ id }) => id === action.payload.id);
      state.items.splice(index, 1);
    })
    .addCase(updateFilter.fulfilled, (state, action) => {
      const index = state.items.findIndex(item => item.id === action.payload.id);
      state.items[index] = {
        ...state.items[index],
        ...action.payload,
      };
    })
    .addCase(addFilter.fulfilled, (state, action) => {
      console.log('**** addFilter addCase', action.payload);
      state.items.push(action.payload);
    })
    .addCase(fetchFilters.fulfilled, (state, action) => {
      state.count = action.payload.length;
      state.items = action.payload;
    })
    .addMatcher(fullFilledActions, (state) => {
      state.fetching = false;
    })
    .addMatcher(pendingActions, (state) => {
      state.fetching = true;
    })
    .addMatcher(rejectedActions, (state, action) => {
      state.fetching = false;
      state.items = [];
    })
});
