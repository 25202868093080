import { Col, Row } from "antd";
import CardGraphicProfession from "../../../components/GraphicsCards/GraphicBehavior/CardGraphicProfession/CardGraphicProfession";
import CardGraphicAverageMonthlyIncome from "../../../components/GraphicsCards/GraphicBehavior/CardGraphicAverageMonthlyIncome/CardGraphicAverageMonthlyIncome";
import CardGraphicHavePartner from "../../../components/GraphicsCards/GraphicBehavior/CardGraphicHavePartner/CardGraphicHavePartner";
import CardGraphicCarBrand from "../../../components/GraphicsCards/GraphicBehavior/CardGraphicCarBrand/CardGraphicCarBrand";

export default function GraphicOfficeSalary() {

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24} md={12}> 
           <CardGraphicProfession />
        </Col>
        <Col span={24} md={12}>
          <CardGraphicAverageMonthlyIncome />
        </Col>
        
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={24} md={12}>
          <CardGraphicHavePartner />
        </Col>
        <Col span={24} md={12}>
          <CardGraphicCarBrand />
        </Col>
      </Row>
    </>
  );
}
