import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import selectUsers from "../selectors/selectUsers";
import * as UserAction from "../store/userSlice";
import { doc, getDoc, onSnapshot } from "firebase/firestore"; 
import { db } from "../../sdk/firebase/firebase-config";
import { SUBSCRIPTION_TYPES, UserSaaS, UserSubscription } from "../../sdk/@types/auth.model";
import selectFilterTags from "../selectors/selectFilterTags";
import { transformFirestoreData } from "../utils/firestoreUtil";

export default function useUsers() {
  const dispatch = useDispatch();
  const state = useSelector(selectUsers);
  const stateFilterTags = useSelector(selectFilterTags);

  const getUser = useCallback(
    async function (userId: string) {
      const userRef = doc(db, "users", userId);
      const user: UserSaaS = await getDoc(userRef).then(res => transformFirestoreData(res) as UserSaaS);
      return user;
    },[]
  );
  
   const activeUser = useCallback(
    async function (user: UserSaaS) {
      // const userRef = doc(db, "users", payload.user.uid);
      // const userSnap: any = (await getDoc(userRef)).data();
      // const userValidation: UserSubscription = validateSubscription(userSnap);
      // dispatch(UserAction.activeUserSubscription(userValidation));
      
       // const user: UserSaaS = {...userSnap, userId: userSnap.uid}
       
       // dispatch(TableAction.fetchTables());
      const userValidation: UserSubscription = validateSubscription(user);
      dispatch(UserAction.activeUserSubscription(userValidation));
      return dispatch(UserAction.activeUser(user));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
   );
  
  const clearUser = useCallback(
    async function () {
      return dispatch(UserAction.clearUser());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
   );
  
  const validateSubscription = (user: UserSaaS) => {
    let userDefault = {
      qtdPerson: 1,
      isAuthorization: false,
      type: SUBSCRIPTION_TYPES.FREEMIUM.toString(),
    }
    if (!user.subscription) { return userDefault; }
    if (user.subscription.status === "canceled") { return userDefault; }
    const sub: any = user.subscription.items[1];

    userDefault.type = 'FREEMIUM';
    userDefault.qtdPerson = 1;
    if (sub.interval === 'month') {
      if (sub.intervalCount === 1) {
        userDefault.type = SUBSCRIPTION_TYPES.MENSAL.toString()
      }
      if (sub.intervalCount === 6) {
        userDefault.type = SUBSCRIPTION_TYPES.SEMESTRAL.toString()
      }      
      userDefault.qtdPerson = sub.quantity;
    } else {
      userDefault.type = SUBSCRIPTION_TYPES.ANUAL
      userDefault.qtdPerson = sub.quantity;
    }
    userDefault.isAuthorization = isAuthorization();
    return userDefault;
  }

  const isAuthorization = (): boolean => {
    let auth = false;
    if (stateFilterTags.items) {
      if (stateFilterTags.items.length >= state.userSubscription.qtdPerson) {
        auth = false;
      } else {
        auth  = true;
      }
    }
    return auth;
  }

  const changeSubscription = useCallback(
    async function (userId: string) {
      return onSnapshot(doc(db, "users", `${userId}`), (doc) => {
        const result: any = doc.data();
        if (result) {
          dispatch(UserAction.activeUser(result));
          const userValidation: UserSubscription = validateSubscription(result);
          dispatch(UserAction.activeUserSubscription(userValidation));
        }
        // console.log('**** STATUS SUBSCRIPTION', result);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[dispatch]
  );

  return {
    getUser,
    activeUser,
    changeSubscription,
    clearUser,
    stateUser: state,
    items: state.items,
  };
}
