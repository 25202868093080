import { Skeleton, Space } from "antd";
import Meta from "antd/lib/card/Meta";
import * as CF from "../../CardGrapich.styles";
import { useCallback, useEffect, useState } from "react";
import { GRAPHIC_TYPES } from "../../../../../sdk/@types/graphic.model";
import useFilters from "../../../../../core/hooks/useFilters";
import { Column } from "@ant-design/plots";
import _ from 'lodash';
import useGraphicAll from "../../../../../core/hooks/useGraphicAll";

export default function CardGraphicAge() {

  const { stateFilters } = useFilters();
  const [config, setConfig ] = useState<any>();
  const { stateGraphicAll } = useGraphicAll();

  const getItems = useCallback(async (items: any) => {
    // É POSSIVEL QUE O FILTRO DE IDADE NAO TENHA SIDO DEFINIDO 
    // ENTAO SETAMOS UM VALOR PADRAO
    const filters: any = stateFilters.items.find(i => i.type === GRAPHIC_TYPES.IDADE);
    const ageStart: any = filters ? filters.valueNumbers[0] : 15;
    const ageEnd: any = filters ? filters.valueNumbers[1] : 80;
    const data = items.filter((i: any) => i.idade >= ageStart && i.idade <= ageEnd);
    
    const result = _(data)
      .groupBy('idade')
      .map(function(items, idade) { 
        return { idade, frequencia: items.length };
    }).value();
    
    console.log('*** RESULT IDADE MAP', result);
    const config = {
        data: result,
        columnWidthRatio: 1,
        xField: 'idade',
        yField: 'frequencia',
        xAxis: {
          label: {
            autoHide: true,
            autoRotate: false,
          },
        },
        yAxis: false,
        meta: {
          idade: {
            alias: '类别',
          },
          frequencia: {
            alias: '销售额',
          },
        },
        tooltip: {
          formatter: (datum: any) => {
            return {
              title: null,
              name: 'Idade',
              value: `${datum.idade}`,
            };
          },
        },
      };
      setConfig(config)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ATUALIZA O COMPONENTE QUANDO ELE É INICIADO E QUANDO A LISTA DE FILTROS É ALTERADA
  useEffect(() => {
    console.log('***** ITEMS', stateGraphicAll.items)
    if (stateGraphicAll.items && stateGraphicAll.items.length > 0) {
      getItems(stateGraphicAll.items);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateGraphicAll.items]);

  return (
    <>
      <CF.Wrapper>
        <Skeleton loading={!config} avatar active>
          <Space direction="vertical" size="large" style={{ display: "flex" }}>
            <Meta title="Gráfico de Idade" />
            <Column {...config} />
          </Space>
        </Skeleton>
      </CF.Wrapper>
    </>
  );
  
}
