import { DocumentSnapshot } from "firebase/firestore";

export function transformFirestoreData(querySnapshot: DocumentSnapshot) {
  if (!querySnapshot.exists) {
    throw new Error(`Nenhum documento encontrado`);
  }

  const item = querySnapshot.data();

  return {
    uid: querySnapshot.id,
    ...item,
  };
}
