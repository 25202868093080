import Service from "../Service";
class AuthService extends Service {
    
  static authorization(userId: string | null) {
    return this.HttpFilter
      .post('/auth/authorization', { userId })
      .then(this.getData)
  }
}

export default AuthService