import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';

import reportWebVitals from './reportWebVitals';
import store from "./core/store";
import PageRoutes from './app/routes';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { ConfigProvider } from 'antd';
import { UserAuthContextProvider } from "./sdk/firebase/UserAuthContext";

let persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ConfigProvider
              theme={{
                token: {
                  colorPrimary: '#09f',
                  colorBgContainer: '#fff',
                  colorBgLayout: '#fff'
                },
              }}
            >
            <UserAuthContextProvider>
              <BrowserRouter>
                  <PageRoutes />
              </BrowserRouter>
            </UserAuthContextProvider>
          </ConfigProvider>
        </PersistGate>
      </Provider>,
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
