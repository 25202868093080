import styled from "styled-components";


export const Card = styled.div<{ size: number | string }>`
    border-radius: 6px;
    border-style: 1px solid blue;
    background-color: #f4f4f4;
    overflow:hidden;
    width: size;
    max-height: 380px;
    margin: 0 auto;
    // width: ${(p) => p.size};
`
export const Header = styled.div<{ imgGround: string }>`
    height: 150px;
    width: 100%;
    background: red;
    background-image: url(${p => p.imgGround});
    background-position: center;
    background-size: cover;

    border-top-left-radius: 10;
    border-top-right-radius: 10;
`

export const WrapperAvatar = styled.div`
    top: -70px;
    left: 0;
    margin: auto 0;
    position: relative;
    height: 100px;
    width: 100%;
    justify-content: center;
    display: flex;
    
`

export const Avatar = styled.img.attrs(props => ({
    src: props.src
  }))`
    border-radius: 50%;
    border: solid white;
    border-width: 0 3px;
    height: 120px;
    width: 120px;
`

export const Body = styled.div`
    text-align: center;
    position: relative;
    top: -20px;
`

export const Title = styled.h1`
    color: #58688c;
    font-size: 1rem;
`

export const SubTitle = styled.h3`
    color: #908f8f;
    font-size: 0.9rem;
`

export const TextTitle = styled.h3`
    color: #58688c;
    font-size: 1rem;
    text-align: left;
    text-transform: uppercase;
`

export const TextBody = styled.p`
    color: #908f8f;
    font-size: 0.9rem;
    text-align: left;
`

