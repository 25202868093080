import { Avatar } from "antd";
import Meta from "antd/lib/card/Meta";
import * as UA from './UserAvatar.styles';
import SubscriptionStatus from "./SubscriptionStatus";
import useUsers from "../../../core/hooks/useUsers";
import UserHorus from "./UserHorus";

export default function UserAvatar() {
    const { stateUser } = useUsers();
    
    if (!stateUser.userAuthenticated) { return null }
    return (
        <UA.Wrapper>
            <Meta
            avatar={<Avatar src="../../../assets/theme/team-2.jpg" />}
            title={stateUser.userAuthenticated.name}
            description={stateUser.userAuthenticated.email}
            /><br/>
            <SubscriptionStatus subscription={stateUser.userSubscription} /> 
            <UserHorus />
        </UA.Wrapper>
    )
}