import { Filter } from "../@types/filter.model";
import Service from "../Service";

class UploadService extends Service {
    
static getAll(teamId: string) {
    console.log("PATH", teamId);
    return this.HttpFilter
      .get<any[]>(`/uploads?teamId=${teamId}`)
      .then(this.getData)
  }

  static findBy (id: string) {
    return this.HttpFilter
      .get<any>(`/uploads/${id}`)
      .then(this.getData)
  }

  static create(post: Filter) {
    console.log('*** ADD FILTER', post);
    return this.HttpFilter
      .post<any>('/uploads', post)
      .then(this.getData)
  }

  static update (filterId: string, filter: Filter) {
    return this.HttpFilter
      .put<any>(`/uploads/${filterId}`, filter)
      .then(this.getData)
  }

  static delete (id: string) {
    return this.HttpFilter
      .delete<any>(`/uploads/${id}`)
      .then(this.getData)
  }

}

export default UploadService